@font-face {
  font-family: "Noto Color Emoji";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("/content/assets/fonts/NotoColorEmoji-Regular.ttf") format("truetype");
}
h1,
h2,
h3 {
  font-family: "Josefin Sans", "Helvetica Neue", Helvetica, Arial, sans-serif, "Noto Color Emoji", sans-serif !important;
}

h4 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif, "Noto Color Emoji", sans-serif !important;
}

p {
  font-family: Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif, "Noto Color Emoji", sans-serif !important;
}

.mt-0 {
  margin-top: 0rem;
}

.pt-0 {
  padding-top: 0rem;
}

.mr-0 {
  margin-right: 0rem;
}

.pr-0 {
  padding-right: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.pb-0 {
  padding-bottom: 0rem;
}

.ml-0 {
  margin-left: 0rem;
}

.pl-0 {
  padding-left: 0rem;
}

.m-0 {
  margin: 0rem;
}

.p-0 {
  padding: 0rem;
}

.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.mx-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.px-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.mt-1 {
  margin-top: 0.3125rem;
}

.pt-1 {
  padding-top: 0.3125rem;
}

.mr-1 {
  margin-right: 0.3125rem;
}

.pr-1 {
  padding-right: 0.3125rem;
}

.mb-1 {
  margin-bottom: 0.3125rem;
}

.pb-1 {
  padding-bottom: 0.3125rem;
}

.ml-1 {
  margin-left: 0.3125rem;
}

.pl-1 {
  padding-left: 0.3125rem;
}

.m-1 {
  margin: 0.3125rem;
}

.p-1 {
  padding: 0.3125rem;
}

.my-1 {
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.py-1 {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

.mx-1 {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}

.px-1 {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

.mt-2 {
  margin-top: 0.625rem;
}

.pt-2 {
  padding-top: 0.625rem;
}

.mr-2 {
  margin-right: 0.625rem;
}

.pr-2 {
  padding-right: 0.625rem;
}

.mb-2 {
  margin-bottom: 0.625rem;
}

.pb-2 {
  padding-bottom: 0.625rem;
}

.ml-2 {
  margin-left: 0.625rem;
}

.pl-2 {
  padding-left: 0.625rem;
}

.m-2 {
  margin: 0.625rem;
}

.p-2 {
  padding: 0.625rem;
}

.my-2 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.py-2 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.mx-2 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.px-2 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.mt-3 {
  margin-top: 0.9375rem;
}

.pt-3 {
  padding-top: 0.9375rem;
}

.mr-3 {
  margin-right: 0.9375rem;
}

.pr-3 {
  padding-right: 0.9375rem;
}

.mb-3 {
  margin-bottom: 0.9375rem;
}

.pb-3 {
  padding-bottom: 0.9375rem;
}

.ml-3 {
  margin-left: 0.9375rem;
}

.pl-3 {
  padding-left: 0.9375rem;
}

.m-3 {
  margin: 0.9375rem;
}

.p-3 {
  padding: 0.9375rem;
}

.my-3 {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}

.py-3 {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}

.mx-3 {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
}

.px-3 {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.mt-4 {
  margin-top: 1.25rem;
}

.pt-4 {
  padding-top: 1.25rem;
}

.mr-4 {
  margin-right: 1.25rem;
}

.pr-4 {
  padding-right: 1.25rem;
}

.mb-4 {
  margin-bottom: 1.25rem;
}

.pb-4 {
  padding-bottom: 1.25rem;
}

.ml-4 {
  margin-left: 1.25rem;
}

.pl-4 {
  padding-left: 1.25rem;
}

.m-4 {
  margin: 1.25rem;
}

.p-4 {
  padding: 1.25rem;
}

.my-4 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.py-4 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.mx-4 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.px-4 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.mt-5 {
  margin-top: 1.5625rem;
}

.pt-5 {
  padding-top: 1.5625rem;
}

.mr-5 {
  margin-right: 1.5625rem;
}

.pr-5 {
  padding-right: 1.5625rem;
}

.mb-5 {
  margin-bottom: 1.5625rem;
}

.pb-5 {
  padding-bottom: 1.5625rem;
}

.ml-5 {
  margin-left: 1.5625rem;
}

.pl-5 {
  padding-left: 1.5625rem;
}

.m-5 {
  margin: 1.5625rem;
}

.p-5 {
  padding: 1.5625rem;
}

.my-5 {
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
}

.py-5 {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
}

.mx-5 {
  margin-left: 1.5625rem;
  margin-right: 1.5625rem;
}

.px-5 {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.mt-6 {
  margin-top: 1.875rem;
}

.pt-6 {
  padding-top: 1.875rem;
}

.mr-6 {
  margin-right: 1.875rem;
}

.pr-6 {
  padding-right: 1.875rem;
}

.mb-6 {
  margin-bottom: 1.875rem;
}

.pb-6 {
  padding-bottom: 1.875rem;
}

.ml-6 {
  margin-left: 1.875rem;
}

.pl-6 {
  padding-left: 1.875rem;
}

.m-6 {
  margin: 1.875rem;
}

.p-6 {
  padding: 1.875rem;
}

.my-6 {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}

.py-6 {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.mx-6 {
  margin-left: 1.875rem;
  margin-right: 1.875rem;
}

.px-6 {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.mt-7 {
  margin-top: 2.1875rem;
}

.pt-7 {
  padding-top: 2.1875rem;
}

.mr-7 {
  margin-right: 2.1875rem;
}

.pr-7 {
  padding-right: 2.1875rem;
}

.mb-7 {
  margin-bottom: 2.1875rem;
}

.pb-7 {
  padding-bottom: 2.1875rem;
}

.ml-7 {
  margin-left: 2.1875rem;
}

.pl-7 {
  padding-left: 2.1875rem;
}

.m-7 {
  margin: 2.1875rem;
}

.p-7 {
  padding: 2.1875rem;
}

.my-7 {
  margin-top: 2.1875rem;
  margin-bottom: 2.1875rem;
}

.py-7 {
  padding-top: 2.1875rem;
  padding-bottom: 2.1875rem;
}

.mx-7 {
  margin-left: 2.1875rem;
  margin-right: 2.1875rem;
}

.px-7 {
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
}

.mt-8 {
  margin-top: 2.5rem;
}

.pt-8 {
  padding-top: 2.5rem;
}

.mr-8 {
  margin-right: 2.5rem;
}

.pr-8 {
  padding-right: 2.5rem;
}

.mb-8 {
  margin-bottom: 2.5rem;
}

.pb-8 {
  padding-bottom: 2.5rem;
}

.ml-8 {
  margin-left: 2.5rem;
}

.pl-8 {
  padding-left: 2.5rem;
}

.m-8 {
  margin: 2.5rem;
}

.p-8 {
  padding: 2.5rem;
}

.my-8 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.py-8 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.mx-8 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.px-8 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.mt-9 {
  margin-top: 2.8125rem;
}

.pt-9 {
  padding-top: 2.8125rem;
}

.mr-9 {
  margin-right: 2.8125rem;
}

.pr-9 {
  padding-right: 2.8125rem;
}

.mb-9 {
  margin-bottom: 2.8125rem;
}

.pb-9 {
  padding-bottom: 2.8125rem;
}

.ml-9 {
  margin-left: 2.8125rem;
}

.pl-9 {
  padding-left: 2.8125rem;
}

.m-9 {
  margin: 2.8125rem;
}

.p-9 {
  padding: 2.8125rem;
}

.my-9 {
  margin-top: 2.8125rem;
  margin-bottom: 2.8125rem;
}

.py-9 {
  padding-top: 2.8125rem;
  padding-bottom: 2.8125rem;
}

.mx-9 {
  margin-left: 2.8125rem;
  margin-right: 2.8125rem;
}

.px-9 {
  padding-left: 2.8125rem;
  padding-right: 2.8125rem;
}

.mt-10 {
  margin-top: 3.125rem;
}

.pt-10 {
  padding-top: 3.125rem;
}

.mr-10 {
  margin-right: 3.125rem;
}

.pr-10 {
  padding-right: 3.125rem;
}

.mb-10 {
  margin-bottom: 3.125rem;
}

.pb-10 {
  padding-bottom: 3.125rem;
}

.ml-10 {
  margin-left: 3.125rem;
}

.pl-10 {
  padding-left: 3.125rem;
}

.m-10 {
  margin: 3.125rem;
}

.p-10 {
  padding: 3.125rem;
}

.my-10 {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}

.py-10 {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

.mx-10 {
  margin-left: 3.125rem;
  margin-right: 3.125rem;
}

.px-10 {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}

.mt-11 {
  margin-top: 3.4375rem;
}

.pt-11 {
  padding-top: 3.4375rem;
}

.mr-11 {
  margin-right: 3.4375rem;
}

.pr-11 {
  padding-right: 3.4375rem;
}

.mb-11 {
  margin-bottom: 3.4375rem;
}

.pb-11 {
  padding-bottom: 3.4375rem;
}

.ml-11 {
  margin-left: 3.4375rem;
}

.pl-11 {
  padding-left: 3.4375rem;
}

.m-11 {
  margin: 3.4375rem;
}

.p-11 {
  padding: 3.4375rem;
}

.my-11 {
  margin-top: 3.4375rem;
  margin-bottom: 3.4375rem;
}

.py-11 {
  padding-top: 3.4375rem;
  padding-bottom: 3.4375rem;
}

.mx-11 {
  margin-left: 3.4375rem;
  margin-right: 3.4375rem;
}

.px-11 {
  padding-left: 3.4375rem;
  padding-right: 3.4375rem;
}

.mt-12 {
  margin-top: 3.75rem;
}

.pt-12 {
  padding-top: 3.75rem;
}

.mr-12 {
  margin-right: 3.75rem;
}

.pr-12 {
  padding-right: 3.75rem;
}

.mb-12 {
  margin-bottom: 3.75rem;
}

.pb-12 {
  padding-bottom: 3.75rem;
}

.ml-12 {
  margin-left: 3.75rem;
}

.pl-12 {
  padding-left: 3.75rem;
}

.m-12 {
  margin: 3.75rem;
}

.p-12 {
  padding: 3.75rem;
}

.my-12 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.py-12 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.mx-12 {
  margin-left: 3.75rem;
  margin-right: 3.75rem;
}

.px-12 {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}

.mt-13 {
  margin-top: 4.0625rem;
}

.pt-13 {
  padding-top: 4.0625rem;
}

.mr-13 {
  margin-right: 4.0625rem;
}

.pr-13 {
  padding-right: 4.0625rem;
}

.mb-13 {
  margin-bottom: 4.0625rem;
}

.pb-13 {
  padding-bottom: 4.0625rem;
}

.ml-13 {
  margin-left: 4.0625rem;
}

.pl-13 {
  padding-left: 4.0625rem;
}

.m-13 {
  margin: 4.0625rem;
}

.p-13 {
  padding: 4.0625rem;
}

.my-13 {
  margin-top: 4.0625rem;
  margin-bottom: 4.0625rem;
}

.py-13 {
  padding-top: 4.0625rem;
  padding-bottom: 4.0625rem;
}

.mx-13 {
  margin-left: 4.0625rem;
  margin-right: 4.0625rem;
}

.px-13 {
  padding-left: 4.0625rem;
  padding-right: 4.0625rem;
}

.mt-14 {
  margin-top: 4.375rem;
}

.pt-14 {
  padding-top: 4.375rem;
}

.mr-14 {
  margin-right: 4.375rem;
}

.pr-14 {
  padding-right: 4.375rem;
}

.mb-14 {
  margin-bottom: 4.375rem;
}

.pb-14 {
  padding-bottom: 4.375rem;
}

.ml-14 {
  margin-left: 4.375rem;
}

.pl-14 {
  padding-left: 4.375rem;
}

.m-14 {
  margin: 4.375rem;
}

.p-14 {
  padding: 4.375rem;
}

.my-14 {
  margin-top: 4.375rem;
  margin-bottom: 4.375rem;
}

.py-14 {
  padding-top: 4.375rem;
  padding-bottom: 4.375rem;
}

.mx-14 {
  margin-left: 4.375rem;
  margin-right: 4.375rem;
}

.px-14 {
  padding-left: 4.375rem;
  padding-right: 4.375rem;
}

.mt-15 {
  margin-top: 4.6875rem;
}

.pt-15 {
  padding-top: 4.6875rem;
}

.mr-15 {
  margin-right: 4.6875rem;
}

.pr-15 {
  padding-right: 4.6875rem;
}

.mb-15 {
  margin-bottom: 4.6875rem;
}

.pb-15 {
  padding-bottom: 4.6875rem;
}

.ml-15 {
  margin-left: 4.6875rem;
}

.pl-15 {
  padding-left: 4.6875rem;
}

.m-15 {
  margin: 4.6875rem;
}

.p-15 {
  padding: 4.6875rem;
}

.my-15 {
  margin-top: 4.6875rem;
  margin-bottom: 4.6875rem;
}

.py-15 {
  padding-top: 4.6875rem;
  padding-bottom: 4.6875rem;
}

.mx-15 {
  margin-left: 4.6875rem;
  margin-right: 4.6875rem;
}

.px-15 {
  padding-left: 4.6875rem;
  padding-right: 4.6875rem;
}

.mt-16 {
  margin-top: 5rem;
}

.pt-16 {
  padding-top: 5rem;
}

.mr-16 {
  margin-right: 5rem;
}

.pr-16 {
  padding-right: 5rem;
}

.mb-16 {
  margin-bottom: 5rem;
}

.pb-16 {
  padding-bottom: 5rem;
}

.ml-16 {
  margin-left: 5rem;
}

.pl-16 {
  padding-left: 5rem;
}

.m-16 {
  margin: 5rem;
}

.p-16 {
  padding: 5rem;
}

.my-16 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.py-16 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.mx-16 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.px-16 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.mt-17 {
  margin-top: 5.3125rem;
}

.pt-17 {
  padding-top: 5.3125rem;
}

.mr-17 {
  margin-right: 5.3125rem;
}

.pr-17 {
  padding-right: 5.3125rem;
}

.mb-17 {
  margin-bottom: 5.3125rem;
}

.pb-17 {
  padding-bottom: 5.3125rem;
}

.ml-17 {
  margin-left: 5.3125rem;
}

.pl-17 {
  padding-left: 5.3125rem;
}

.m-17 {
  margin: 5.3125rem;
}

.p-17 {
  padding: 5.3125rem;
}

.my-17 {
  margin-top: 5.3125rem;
  margin-bottom: 5.3125rem;
}

.py-17 {
  padding-top: 5.3125rem;
  padding-bottom: 5.3125rem;
}

.mx-17 {
  margin-left: 5.3125rem;
  margin-right: 5.3125rem;
}

.px-17 {
  padding-left: 5.3125rem;
  padding-right: 5.3125rem;
}

.mt-18 {
  margin-top: 5.625rem;
}

.pt-18 {
  padding-top: 5.625rem;
}

.mr-18 {
  margin-right: 5.625rem;
}

.pr-18 {
  padding-right: 5.625rem;
}

.mb-18 {
  margin-bottom: 5.625rem;
}

.pb-18 {
  padding-bottom: 5.625rem;
}

.ml-18 {
  margin-left: 5.625rem;
}

.pl-18 {
  padding-left: 5.625rem;
}

.m-18 {
  margin: 5.625rem;
}

.p-18 {
  padding: 5.625rem;
}

.my-18 {
  margin-top: 5.625rem;
  margin-bottom: 5.625rem;
}

.py-18 {
  padding-top: 5.625rem;
  padding-bottom: 5.625rem;
}

.mx-18 {
  margin-left: 5.625rem;
  margin-right: 5.625rem;
}

.px-18 {
  padding-left: 5.625rem;
  padding-right: 5.625rem;
}

.mt-19 {
  margin-top: 5.9375rem;
}

.pt-19 {
  padding-top: 5.9375rem;
}

.mr-19 {
  margin-right: 5.9375rem;
}

.pr-19 {
  padding-right: 5.9375rem;
}

.mb-19 {
  margin-bottom: 5.9375rem;
}

.pb-19 {
  padding-bottom: 5.9375rem;
}

.ml-19 {
  margin-left: 5.9375rem;
}

.pl-19 {
  padding-left: 5.9375rem;
}

.m-19 {
  margin: 5.9375rem;
}

.p-19 {
  padding: 5.9375rem;
}

.my-19 {
  margin-top: 5.9375rem;
  margin-bottom: 5.9375rem;
}

.py-19 {
  padding-top: 5.9375rem;
  padding-bottom: 5.9375rem;
}

.mx-19 {
  margin-left: 5.9375rem;
  margin-right: 5.9375rem;
}

.px-19 {
  padding-left: 5.9375rem;
  padding-right: 5.9375rem;
}

.mt-20 {
  margin-top: 6.25rem;
}

.pt-20 {
  padding-top: 6.25rem;
}

.mr-20 {
  margin-right: 6.25rem;
}

.pr-20 {
  padding-right: 6.25rem;
}

.mb-20 {
  margin-bottom: 6.25rem;
}

.pb-20 {
  padding-bottom: 6.25rem;
}

.ml-20 {
  margin-left: 6.25rem;
}

.pl-20 {
  padding-left: 6.25rem;
}

.m-20 {
  margin: 6.25rem;
}

.p-20 {
  padding: 6.25rem;
}

.my-20 {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
}

.py-20 {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}

.mx-20 {
  margin-left: 6.25rem;
  margin-right: 6.25rem;
}

.px-20 {
  padding-left: 6.25rem;
  padding-right: 6.25rem;
}

.mt-21 {
  margin-top: 6.5625rem;
}

.pt-21 {
  padding-top: 6.5625rem;
}

.mr-21 {
  margin-right: 6.5625rem;
}

.pr-21 {
  padding-right: 6.5625rem;
}

.mb-21 {
  margin-bottom: 6.5625rem;
}

.pb-21 {
  padding-bottom: 6.5625rem;
}

.ml-21 {
  margin-left: 6.5625rem;
}

.pl-21 {
  padding-left: 6.5625rem;
}

.m-21 {
  margin: 6.5625rem;
}

.p-21 {
  padding: 6.5625rem;
}

.my-21 {
  margin-top: 6.5625rem;
  margin-bottom: 6.5625rem;
}

.py-21 {
  padding-top: 6.5625rem;
  padding-bottom: 6.5625rem;
}

.mx-21 {
  margin-left: 6.5625rem;
  margin-right: 6.5625rem;
}

.px-21 {
  padding-left: 6.5625rem;
  padding-right: 6.5625rem;
}

.mt-22 {
  margin-top: 6.875rem;
}

.pt-22 {
  padding-top: 6.875rem;
}

.mr-22 {
  margin-right: 6.875rem;
}

.pr-22 {
  padding-right: 6.875rem;
}

.mb-22 {
  margin-bottom: 6.875rem;
}

.pb-22 {
  padding-bottom: 6.875rem;
}

.ml-22 {
  margin-left: 6.875rem;
}

.pl-22 {
  padding-left: 6.875rem;
}

.m-22 {
  margin: 6.875rem;
}

.p-22 {
  padding: 6.875rem;
}

.my-22 {
  margin-top: 6.875rem;
  margin-bottom: 6.875rem;
}

.py-22 {
  padding-top: 6.875rem;
  padding-bottom: 6.875rem;
}

.mx-22 {
  margin-left: 6.875rem;
  margin-right: 6.875rem;
}

.px-22 {
  padding-left: 6.875rem;
  padding-right: 6.875rem;
}

.mt-23 {
  margin-top: 7.1875rem;
}

.pt-23 {
  padding-top: 7.1875rem;
}

.mr-23 {
  margin-right: 7.1875rem;
}

.pr-23 {
  padding-right: 7.1875rem;
}

.mb-23 {
  margin-bottom: 7.1875rem;
}

.pb-23 {
  padding-bottom: 7.1875rem;
}

.ml-23 {
  margin-left: 7.1875rem;
}

.pl-23 {
  padding-left: 7.1875rem;
}

.m-23 {
  margin: 7.1875rem;
}

.p-23 {
  padding: 7.1875rem;
}

.my-23 {
  margin-top: 7.1875rem;
  margin-bottom: 7.1875rem;
}

.py-23 {
  padding-top: 7.1875rem;
  padding-bottom: 7.1875rem;
}

.mx-23 {
  margin-left: 7.1875rem;
  margin-right: 7.1875rem;
}

.px-23 {
  padding-left: 7.1875rem;
  padding-right: 7.1875rem;
}

.mt-24 {
  margin-top: 7.5rem;
}

.pt-24 {
  padding-top: 7.5rem;
}

.mr-24 {
  margin-right: 7.5rem;
}

.pr-24 {
  padding-right: 7.5rem;
}

.mb-24 {
  margin-bottom: 7.5rem;
}

.pb-24 {
  padding-bottom: 7.5rem;
}

.ml-24 {
  margin-left: 7.5rem;
}

.pl-24 {
  padding-left: 7.5rem;
}

.m-24 {
  margin: 7.5rem;
}

.p-24 {
  padding: 7.5rem;
}

.my-24 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.py-24 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.mx-24 {
  margin-left: 7.5rem;
  margin-right: 7.5rem;
}

.px-24 {
  padding-left: 7.5rem;
  padding-right: 7.5rem;
}

.mt-25 {
  margin-top: 7.8125rem;
}

.pt-25 {
  padding-top: 7.8125rem;
}

.mr-25 {
  margin-right: 7.8125rem;
}

.pr-25 {
  padding-right: 7.8125rem;
}

.mb-25 {
  margin-bottom: 7.8125rem;
}

.pb-25 {
  padding-bottom: 7.8125rem;
}

.ml-25 {
  margin-left: 7.8125rem;
}

.pl-25 {
  padding-left: 7.8125rem;
}

.m-25 {
  margin: 7.8125rem;
}

.p-25 {
  padding: 7.8125rem;
}

.my-25 {
  margin-top: 7.8125rem;
  margin-bottom: 7.8125rem;
}

.py-25 {
  padding-top: 7.8125rem;
  padding-bottom: 7.8125rem;
}

.mx-25 {
  margin-left: 7.8125rem;
  margin-right: 7.8125rem;
}

.px-25 {
  padding-left: 7.8125rem;
  padding-right: 7.8125rem;
}

.mt-26 {
  margin-top: 8.125rem;
}

.pt-26 {
  padding-top: 8.125rem;
}

.mr-26 {
  margin-right: 8.125rem;
}

.pr-26 {
  padding-right: 8.125rem;
}

.mb-26 {
  margin-bottom: 8.125rem;
}

.pb-26 {
  padding-bottom: 8.125rem;
}

.ml-26 {
  margin-left: 8.125rem;
}

.pl-26 {
  padding-left: 8.125rem;
}

.m-26 {
  margin: 8.125rem;
}

.p-26 {
  padding: 8.125rem;
}

.my-26 {
  margin-top: 8.125rem;
  margin-bottom: 8.125rem;
}

.py-26 {
  padding-top: 8.125rem;
  padding-bottom: 8.125rem;
}

.mx-26 {
  margin-left: 8.125rem;
  margin-right: 8.125rem;
}

.px-26 {
  padding-left: 8.125rem;
  padding-right: 8.125rem;
}

.mt-27 {
  margin-top: 8.4375rem;
}

.pt-27 {
  padding-top: 8.4375rem;
}

.mr-27 {
  margin-right: 8.4375rem;
}

.pr-27 {
  padding-right: 8.4375rem;
}

.mb-27 {
  margin-bottom: 8.4375rem;
}

.pb-27 {
  padding-bottom: 8.4375rem;
}

.ml-27 {
  margin-left: 8.4375rem;
}

.pl-27 {
  padding-left: 8.4375rem;
}

.m-27 {
  margin: 8.4375rem;
}

.p-27 {
  padding: 8.4375rem;
}

.my-27 {
  margin-top: 8.4375rem;
  margin-bottom: 8.4375rem;
}

.py-27 {
  padding-top: 8.4375rem;
  padding-bottom: 8.4375rem;
}

.mx-27 {
  margin-left: 8.4375rem;
  margin-right: 8.4375rem;
}

.px-27 {
  padding-left: 8.4375rem;
  padding-right: 8.4375rem;
}

.mt-28 {
  margin-top: 8.75rem;
}

.pt-28 {
  padding-top: 8.75rem;
}

.mr-28 {
  margin-right: 8.75rem;
}

.pr-28 {
  padding-right: 8.75rem;
}

.mb-28 {
  margin-bottom: 8.75rem;
}

.pb-28 {
  padding-bottom: 8.75rem;
}

.ml-28 {
  margin-left: 8.75rem;
}

.pl-28 {
  padding-left: 8.75rem;
}

.m-28 {
  margin: 8.75rem;
}

.p-28 {
  padding: 8.75rem;
}

.my-28 {
  margin-top: 8.75rem;
  margin-bottom: 8.75rem;
}

.py-28 {
  padding-top: 8.75rem;
  padding-bottom: 8.75rem;
}

.mx-28 {
  margin-left: 8.75rem;
  margin-right: 8.75rem;
}

.px-28 {
  padding-left: 8.75rem;
  padding-right: 8.75rem;
}

.mt-29 {
  margin-top: 9.0625rem;
}

.pt-29 {
  padding-top: 9.0625rem;
}

.mr-29 {
  margin-right: 9.0625rem;
}

.pr-29 {
  padding-right: 9.0625rem;
}

.mb-29 {
  margin-bottom: 9.0625rem;
}

.pb-29 {
  padding-bottom: 9.0625rem;
}

.ml-29 {
  margin-left: 9.0625rem;
}

.pl-29 {
  padding-left: 9.0625rem;
}

.m-29 {
  margin: 9.0625rem;
}

.p-29 {
  padding: 9.0625rem;
}

.my-29 {
  margin-top: 9.0625rem;
  margin-bottom: 9.0625rem;
}

.py-29 {
  padding-top: 9.0625rem;
  padding-bottom: 9.0625rem;
}

.mx-29 {
  margin-left: 9.0625rem;
  margin-right: 9.0625rem;
}

.px-29 {
  padding-left: 9.0625rem;
  padding-right: 9.0625rem;
}

.mt-30 {
  margin-top: 9.375rem;
}

.pt-30 {
  padding-top: 9.375rem;
}

.mr-30 {
  margin-right: 9.375rem;
}

.pr-30 {
  padding-right: 9.375rem;
}

.mb-30 {
  margin-bottom: 9.375rem;
}

.pb-30 {
  padding-bottom: 9.375rem;
}

.ml-30 {
  margin-left: 9.375rem;
}

.pl-30 {
  padding-left: 9.375rem;
}

.m-30 {
  margin: 9.375rem;
}

.p-30 {
  padding: 9.375rem;
}

.my-30 {
  margin-top: 9.375rem;
  margin-bottom: 9.375rem;
}

.py-30 {
  padding-top: 9.375rem;
  padding-bottom: 9.375rem;
}

.mx-30 {
  margin-left: 9.375rem;
  margin-right: 9.375rem;
}

.px-30 {
  padding-left: 9.375rem;
  padding-right: 9.375rem;
}

.mt-31 {
  margin-top: 9.6875rem;
}

.pt-31 {
  padding-top: 9.6875rem;
}

.mr-31 {
  margin-right: 9.6875rem;
}

.pr-31 {
  padding-right: 9.6875rem;
}

.mb-31 {
  margin-bottom: 9.6875rem;
}

.pb-31 {
  padding-bottom: 9.6875rem;
}

.ml-31 {
  margin-left: 9.6875rem;
}

.pl-31 {
  padding-left: 9.6875rem;
}

.m-31 {
  margin: 9.6875rem;
}

.p-31 {
  padding: 9.6875rem;
}

.my-31 {
  margin-top: 9.6875rem;
  margin-bottom: 9.6875rem;
}

.py-31 {
  padding-top: 9.6875rem;
  padding-bottom: 9.6875rem;
}

.mx-31 {
  margin-left: 9.6875rem;
  margin-right: 9.6875rem;
}

.px-31 {
  padding-left: 9.6875rem;
  padding-right: 9.6875rem;
}

.mt-32 {
  margin-top: 10rem;
}

.pt-32 {
  padding-top: 10rem;
}

.mr-32 {
  margin-right: 10rem;
}

.pr-32 {
  padding-right: 10rem;
}

.mb-32 {
  margin-bottom: 10rem;
}

.pb-32 {
  padding-bottom: 10rem;
}

.ml-32 {
  margin-left: 10rem;
}

.pl-32 {
  padding-left: 10rem;
}

.m-32 {
  margin: 10rem;
}

.p-32 {
  padding: 10rem;
}

.my-32 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.py-32 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.mx-32 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.px-32 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.mt-33 {
  margin-top: 10.3125rem;
}

.pt-33 {
  padding-top: 10.3125rem;
}

.mr-33 {
  margin-right: 10.3125rem;
}

.pr-33 {
  padding-right: 10.3125rem;
}

.mb-33 {
  margin-bottom: 10.3125rem;
}

.pb-33 {
  padding-bottom: 10.3125rem;
}

.ml-33 {
  margin-left: 10.3125rem;
}

.pl-33 {
  padding-left: 10.3125rem;
}

.m-33 {
  margin: 10.3125rem;
}

.p-33 {
  padding: 10.3125rem;
}

.my-33 {
  margin-top: 10.3125rem;
  margin-bottom: 10.3125rem;
}

.py-33 {
  padding-top: 10.3125rem;
  padding-bottom: 10.3125rem;
}

.mx-33 {
  margin-left: 10.3125rem;
  margin-right: 10.3125rem;
}

.px-33 {
  padding-left: 10.3125rem;
  padding-right: 10.3125rem;
}

.mt-34 {
  margin-top: 10.625rem;
}

.pt-34 {
  padding-top: 10.625rem;
}

.mr-34 {
  margin-right: 10.625rem;
}

.pr-34 {
  padding-right: 10.625rem;
}

.mb-34 {
  margin-bottom: 10.625rem;
}

.pb-34 {
  padding-bottom: 10.625rem;
}

.ml-34 {
  margin-left: 10.625rem;
}

.pl-34 {
  padding-left: 10.625rem;
}

.m-34 {
  margin: 10.625rem;
}

.p-34 {
  padding: 10.625rem;
}

.my-34 {
  margin-top: 10.625rem;
  margin-bottom: 10.625rem;
}

.py-34 {
  padding-top: 10.625rem;
  padding-bottom: 10.625rem;
}

.mx-34 {
  margin-left: 10.625rem;
  margin-right: 10.625rem;
}

.px-34 {
  padding-left: 10.625rem;
  padding-right: 10.625rem;
}

.mt-35 {
  margin-top: 10.9375rem;
}

.pt-35 {
  padding-top: 10.9375rem;
}

.mr-35 {
  margin-right: 10.9375rem;
}

.pr-35 {
  padding-right: 10.9375rem;
}

.mb-35 {
  margin-bottom: 10.9375rem;
}

.pb-35 {
  padding-bottom: 10.9375rem;
}

.ml-35 {
  margin-left: 10.9375rem;
}

.pl-35 {
  padding-left: 10.9375rem;
}

.m-35 {
  margin: 10.9375rem;
}

.p-35 {
  padding: 10.9375rem;
}

.my-35 {
  margin-top: 10.9375rem;
  margin-bottom: 10.9375rem;
}

.py-35 {
  padding-top: 10.9375rem;
  padding-bottom: 10.9375rem;
}

.mx-35 {
  margin-left: 10.9375rem;
  margin-right: 10.9375rem;
}

.px-35 {
  padding-left: 10.9375rem;
  padding-right: 10.9375rem;
}

.mt-36 {
  margin-top: 11.25rem;
}

.pt-36 {
  padding-top: 11.25rem;
}

.mr-36 {
  margin-right: 11.25rem;
}

.pr-36 {
  padding-right: 11.25rem;
}

.mb-36 {
  margin-bottom: 11.25rem;
}

.pb-36 {
  padding-bottom: 11.25rem;
}

.ml-36 {
  margin-left: 11.25rem;
}

.pl-36 {
  padding-left: 11.25rem;
}

.m-36 {
  margin: 11.25rem;
}

.p-36 {
  padding: 11.25rem;
}

.my-36 {
  margin-top: 11.25rem;
  margin-bottom: 11.25rem;
}

.py-36 {
  padding-top: 11.25rem;
  padding-bottom: 11.25rem;
}

.mx-36 {
  margin-left: 11.25rem;
  margin-right: 11.25rem;
}

.px-36 {
  padding-left: 11.25rem;
  padding-right: 11.25rem;
}

.mt-37 {
  margin-top: 11.5625rem;
}

.pt-37 {
  padding-top: 11.5625rem;
}

.mr-37 {
  margin-right: 11.5625rem;
}

.pr-37 {
  padding-right: 11.5625rem;
}

.mb-37 {
  margin-bottom: 11.5625rem;
}

.pb-37 {
  padding-bottom: 11.5625rem;
}

.ml-37 {
  margin-left: 11.5625rem;
}

.pl-37 {
  padding-left: 11.5625rem;
}

.m-37 {
  margin: 11.5625rem;
}

.p-37 {
  padding: 11.5625rem;
}

.my-37 {
  margin-top: 11.5625rem;
  margin-bottom: 11.5625rem;
}

.py-37 {
  padding-top: 11.5625rem;
  padding-bottom: 11.5625rem;
}

.mx-37 {
  margin-left: 11.5625rem;
  margin-right: 11.5625rem;
}

.px-37 {
  padding-left: 11.5625rem;
  padding-right: 11.5625rem;
}

.mt-38 {
  margin-top: 11.875rem;
}

.pt-38 {
  padding-top: 11.875rem;
}

.mr-38 {
  margin-right: 11.875rem;
}

.pr-38 {
  padding-right: 11.875rem;
}

.mb-38 {
  margin-bottom: 11.875rem;
}

.pb-38 {
  padding-bottom: 11.875rem;
}

.ml-38 {
  margin-left: 11.875rem;
}

.pl-38 {
  padding-left: 11.875rem;
}

.m-38 {
  margin: 11.875rem;
}

.p-38 {
  padding: 11.875rem;
}

.my-38 {
  margin-top: 11.875rem;
  margin-bottom: 11.875rem;
}

.py-38 {
  padding-top: 11.875rem;
  padding-bottom: 11.875rem;
}

.mx-38 {
  margin-left: 11.875rem;
  margin-right: 11.875rem;
}

.px-38 {
  padding-left: 11.875rem;
  padding-right: 11.875rem;
}

.mt-39 {
  margin-top: 12.1875rem;
}

.pt-39 {
  padding-top: 12.1875rem;
}

.mr-39 {
  margin-right: 12.1875rem;
}

.pr-39 {
  padding-right: 12.1875rem;
}

.mb-39 {
  margin-bottom: 12.1875rem;
}

.pb-39 {
  padding-bottom: 12.1875rem;
}

.ml-39 {
  margin-left: 12.1875rem;
}

.pl-39 {
  padding-left: 12.1875rem;
}

.m-39 {
  margin: 12.1875rem;
}

.p-39 {
  padding: 12.1875rem;
}

.my-39 {
  margin-top: 12.1875rem;
  margin-bottom: 12.1875rem;
}

.py-39 {
  padding-top: 12.1875rem;
  padding-bottom: 12.1875rem;
}

.mx-39 {
  margin-left: 12.1875rem;
  margin-right: 12.1875rem;
}

.px-39 {
  padding-left: 12.1875rem;
  padding-right: 12.1875rem;
}

.mt-40 {
  margin-top: 12.5rem;
}

.pt-40 {
  padding-top: 12.5rem;
}

.mr-40 {
  margin-right: 12.5rem;
}

.pr-40 {
  padding-right: 12.5rem;
}

.mb-40 {
  margin-bottom: 12.5rem;
}

.pb-40 {
  padding-bottom: 12.5rem;
}

.ml-40 {
  margin-left: 12.5rem;
}

.pl-40 {
  padding-left: 12.5rem;
}

.m-40 {
  margin: 12.5rem;
}

.p-40 {
  padding: 12.5rem;
}

.my-40 {
  margin-top: 12.5rem;
  margin-bottom: 12.5rem;
}

.py-40 {
  padding-top: 12.5rem;
  padding-bottom: 12.5rem;
}

.mx-40 {
  margin-left: 12.5rem;
  margin-right: 12.5rem;
}

.px-40 {
  padding-left: 12.5rem;
  padding-right: 12.5rem;
}

.mt-41 {
  margin-top: 12.8125rem;
}

.pt-41 {
  padding-top: 12.8125rem;
}

.mr-41 {
  margin-right: 12.8125rem;
}

.pr-41 {
  padding-right: 12.8125rem;
}

.mb-41 {
  margin-bottom: 12.8125rem;
}

.pb-41 {
  padding-bottom: 12.8125rem;
}

.ml-41 {
  margin-left: 12.8125rem;
}

.pl-41 {
  padding-left: 12.8125rem;
}

.m-41 {
  margin: 12.8125rem;
}

.p-41 {
  padding: 12.8125rem;
}

.my-41 {
  margin-top: 12.8125rem;
  margin-bottom: 12.8125rem;
}

.py-41 {
  padding-top: 12.8125rem;
  padding-bottom: 12.8125rem;
}

.mx-41 {
  margin-left: 12.8125rem;
  margin-right: 12.8125rem;
}

.px-41 {
  padding-left: 12.8125rem;
  padding-right: 12.8125rem;
}

.mt-42 {
  margin-top: 13.125rem;
}

.pt-42 {
  padding-top: 13.125rem;
}

.mr-42 {
  margin-right: 13.125rem;
}

.pr-42 {
  padding-right: 13.125rem;
}

.mb-42 {
  margin-bottom: 13.125rem;
}

.pb-42 {
  padding-bottom: 13.125rem;
}

.ml-42 {
  margin-left: 13.125rem;
}

.pl-42 {
  padding-left: 13.125rem;
}

.m-42 {
  margin: 13.125rem;
}

.p-42 {
  padding: 13.125rem;
}

.my-42 {
  margin-top: 13.125rem;
  margin-bottom: 13.125rem;
}

.py-42 {
  padding-top: 13.125rem;
  padding-bottom: 13.125rem;
}

.mx-42 {
  margin-left: 13.125rem;
  margin-right: 13.125rem;
}

.px-42 {
  padding-left: 13.125rem;
  padding-right: 13.125rem;
}

.mt-43 {
  margin-top: 13.4375rem;
}

.pt-43 {
  padding-top: 13.4375rem;
}

.mr-43 {
  margin-right: 13.4375rem;
}

.pr-43 {
  padding-right: 13.4375rem;
}

.mb-43 {
  margin-bottom: 13.4375rem;
}

.pb-43 {
  padding-bottom: 13.4375rem;
}

.ml-43 {
  margin-left: 13.4375rem;
}

.pl-43 {
  padding-left: 13.4375rem;
}

.m-43 {
  margin: 13.4375rem;
}

.p-43 {
  padding: 13.4375rem;
}

.my-43 {
  margin-top: 13.4375rem;
  margin-bottom: 13.4375rem;
}

.py-43 {
  padding-top: 13.4375rem;
  padding-bottom: 13.4375rem;
}

.mx-43 {
  margin-left: 13.4375rem;
  margin-right: 13.4375rem;
}

.px-43 {
  padding-left: 13.4375rem;
  padding-right: 13.4375rem;
}

.mt-44 {
  margin-top: 13.75rem;
}

.pt-44 {
  padding-top: 13.75rem;
}

.mr-44 {
  margin-right: 13.75rem;
}

.pr-44 {
  padding-right: 13.75rem;
}

.mb-44 {
  margin-bottom: 13.75rem;
}

.pb-44 {
  padding-bottom: 13.75rem;
}

.ml-44 {
  margin-left: 13.75rem;
}

.pl-44 {
  padding-left: 13.75rem;
}

.m-44 {
  margin: 13.75rem;
}

.p-44 {
  padding: 13.75rem;
}

.my-44 {
  margin-top: 13.75rem;
  margin-bottom: 13.75rem;
}

.py-44 {
  padding-top: 13.75rem;
  padding-bottom: 13.75rem;
}

.mx-44 {
  margin-left: 13.75rem;
  margin-right: 13.75rem;
}

.px-44 {
  padding-left: 13.75rem;
  padding-right: 13.75rem;
}

.mt-45 {
  margin-top: 14.0625rem;
}

.pt-45 {
  padding-top: 14.0625rem;
}

.mr-45 {
  margin-right: 14.0625rem;
}

.pr-45 {
  padding-right: 14.0625rem;
}

.mb-45 {
  margin-bottom: 14.0625rem;
}

.pb-45 {
  padding-bottom: 14.0625rem;
}

.ml-45 {
  margin-left: 14.0625rem;
}

.pl-45 {
  padding-left: 14.0625rem;
}

.m-45 {
  margin: 14.0625rem;
}

.p-45 {
  padding: 14.0625rem;
}

.my-45 {
  margin-top: 14.0625rem;
  margin-bottom: 14.0625rem;
}

.py-45 {
  padding-top: 14.0625rem;
  padding-bottom: 14.0625rem;
}

.mx-45 {
  margin-left: 14.0625rem;
  margin-right: 14.0625rem;
}

.px-45 {
  padding-left: 14.0625rem;
  padding-right: 14.0625rem;
}

.mt-46 {
  margin-top: 14.375rem;
}

.pt-46 {
  padding-top: 14.375rem;
}

.mr-46 {
  margin-right: 14.375rem;
}

.pr-46 {
  padding-right: 14.375rem;
}

.mb-46 {
  margin-bottom: 14.375rem;
}

.pb-46 {
  padding-bottom: 14.375rem;
}

.ml-46 {
  margin-left: 14.375rem;
}

.pl-46 {
  padding-left: 14.375rem;
}

.m-46 {
  margin: 14.375rem;
}

.p-46 {
  padding: 14.375rem;
}

.my-46 {
  margin-top: 14.375rem;
  margin-bottom: 14.375rem;
}

.py-46 {
  padding-top: 14.375rem;
  padding-bottom: 14.375rem;
}

.mx-46 {
  margin-left: 14.375rem;
  margin-right: 14.375rem;
}

.px-46 {
  padding-left: 14.375rem;
  padding-right: 14.375rem;
}

.mt-47 {
  margin-top: 14.6875rem;
}

.pt-47 {
  padding-top: 14.6875rem;
}

.mr-47 {
  margin-right: 14.6875rem;
}

.pr-47 {
  padding-right: 14.6875rem;
}

.mb-47 {
  margin-bottom: 14.6875rem;
}

.pb-47 {
  padding-bottom: 14.6875rem;
}

.ml-47 {
  margin-left: 14.6875rem;
}

.pl-47 {
  padding-left: 14.6875rem;
}

.m-47 {
  margin: 14.6875rem;
}

.p-47 {
  padding: 14.6875rem;
}

.my-47 {
  margin-top: 14.6875rem;
  margin-bottom: 14.6875rem;
}

.py-47 {
  padding-top: 14.6875rem;
  padding-bottom: 14.6875rem;
}

.mx-47 {
  margin-left: 14.6875rem;
  margin-right: 14.6875rem;
}

.px-47 {
  padding-left: 14.6875rem;
  padding-right: 14.6875rem;
}

.mt-48 {
  margin-top: 15rem;
}

.pt-48 {
  padding-top: 15rem;
}

.mr-48 {
  margin-right: 15rem;
}

.pr-48 {
  padding-right: 15rem;
}

.mb-48 {
  margin-bottom: 15rem;
}

.pb-48 {
  padding-bottom: 15rem;
}

.ml-48 {
  margin-left: 15rem;
}

.pl-48 {
  padding-left: 15rem;
}

.m-48 {
  margin: 15rem;
}

.p-48 {
  padding: 15rem;
}

.my-48 {
  margin-top: 15rem;
  margin-bottom: 15rem;
}

.py-48 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.mx-48 {
  margin-left: 15rem;
  margin-right: 15rem;
}

.px-48 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.mt-49 {
  margin-top: 15.3125rem;
}

.pt-49 {
  padding-top: 15.3125rem;
}

.mr-49 {
  margin-right: 15.3125rem;
}

.pr-49 {
  padding-right: 15.3125rem;
}

.mb-49 {
  margin-bottom: 15.3125rem;
}

.pb-49 {
  padding-bottom: 15.3125rem;
}

.ml-49 {
  margin-left: 15.3125rem;
}

.pl-49 {
  padding-left: 15.3125rem;
}

.m-49 {
  margin: 15.3125rem;
}

.p-49 {
  padding: 15.3125rem;
}

.my-49 {
  margin-top: 15.3125rem;
  margin-bottom: 15.3125rem;
}

.py-49 {
  padding-top: 15.3125rem;
  padding-bottom: 15.3125rem;
}

.mx-49 {
  margin-left: 15.3125rem;
  margin-right: 15.3125rem;
}

.px-49 {
  padding-left: 15.3125rem;
  padding-right: 15.3125rem;
}

.mt-50 {
  margin-top: 15.625rem;
}

.pt-50 {
  padding-top: 15.625rem;
}

.mr-50 {
  margin-right: 15.625rem;
}

.pr-50 {
  padding-right: 15.625rem;
}

.mb-50 {
  margin-bottom: 15.625rem;
}

.pb-50 {
  padding-bottom: 15.625rem;
}

.ml-50 {
  margin-left: 15.625rem;
}

.pl-50 {
  padding-left: 15.625rem;
}

.m-50 {
  margin: 15.625rem;
}

.p-50 {
  padding: 15.625rem;
}

.my-50 {
  margin-top: 15.625rem;
  margin-bottom: 15.625rem;
}

.py-50 {
  padding-top: 15.625rem;
  padding-bottom: 15.625rem;
}

.mx-50 {
  margin-left: 15.625rem;
  margin-right: 15.625rem;
}

.px-50 {
  padding-left: 15.625rem;
  padding-right: 15.625rem;
}

.mt-51 {
  margin-top: 15.9375rem;
}

.pt-51 {
  padding-top: 15.9375rem;
}

.mr-51 {
  margin-right: 15.9375rem;
}

.pr-51 {
  padding-right: 15.9375rem;
}

.mb-51 {
  margin-bottom: 15.9375rem;
}

.pb-51 {
  padding-bottom: 15.9375rem;
}

.ml-51 {
  margin-left: 15.9375rem;
}

.pl-51 {
  padding-left: 15.9375rem;
}

.m-51 {
  margin: 15.9375rem;
}

.p-51 {
  padding: 15.9375rem;
}

.my-51 {
  margin-top: 15.9375rem;
  margin-bottom: 15.9375rem;
}

.py-51 {
  padding-top: 15.9375rem;
  padding-bottom: 15.9375rem;
}

.mx-51 {
  margin-left: 15.9375rem;
  margin-right: 15.9375rem;
}

.px-51 {
  padding-left: 15.9375rem;
  padding-right: 15.9375rem;
}

.mt-52 {
  margin-top: 16.25rem;
}

.pt-52 {
  padding-top: 16.25rem;
}

.mr-52 {
  margin-right: 16.25rem;
}

.pr-52 {
  padding-right: 16.25rem;
}

.mb-52 {
  margin-bottom: 16.25rem;
}

.pb-52 {
  padding-bottom: 16.25rem;
}

.ml-52 {
  margin-left: 16.25rem;
}

.pl-52 {
  padding-left: 16.25rem;
}

.m-52 {
  margin: 16.25rem;
}

.p-52 {
  padding: 16.25rem;
}

.my-52 {
  margin-top: 16.25rem;
  margin-bottom: 16.25rem;
}

.py-52 {
  padding-top: 16.25rem;
  padding-bottom: 16.25rem;
}

.mx-52 {
  margin-left: 16.25rem;
  margin-right: 16.25rem;
}

.px-52 {
  padding-left: 16.25rem;
  padding-right: 16.25rem;
}

.mt-53 {
  margin-top: 16.5625rem;
}

.pt-53 {
  padding-top: 16.5625rem;
}

.mr-53 {
  margin-right: 16.5625rem;
}

.pr-53 {
  padding-right: 16.5625rem;
}

.mb-53 {
  margin-bottom: 16.5625rem;
}

.pb-53 {
  padding-bottom: 16.5625rem;
}

.ml-53 {
  margin-left: 16.5625rem;
}

.pl-53 {
  padding-left: 16.5625rem;
}

.m-53 {
  margin: 16.5625rem;
}

.p-53 {
  padding: 16.5625rem;
}

.my-53 {
  margin-top: 16.5625rem;
  margin-bottom: 16.5625rem;
}

.py-53 {
  padding-top: 16.5625rem;
  padding-bottom: 16.5625rem;
}

.mx-53 {
  margin-left: 16.5625rem;
  margin-right: 16.5625rem;
}

.px-53 {
  padding-left: 16.5625rem;
  padding-right: 16.5625rem;
}

.mt-54 {
  margin-top: 16.875rem;
}

.pt-54 {
  padding-top: 16.875rem;
}

.mr-54 {
  margin-right: 16.875rem;
}

.pr-54 {
  padding-right: 16.875rem;
}

.mb-54 {
  margin-bottom: 16.875rem;
}

.pb-54 {
  padding-bottom: 16.875rem;
}

.ml-54 {
  margin-left: 16.875rem;
}

.pl-54 {
  padding-left: 16.875rem;
}

.m-54 {
  margin: 16.875rem;
}

.p-54 {
  padding: 16.875rem;
}

.my-54 {
  margin-top: 16.875rem;
  margin-bottom: 16.875rem;
}

.py-54 {
  padding-top: 16.875rem;
  padding-bottom: 16.875rem;
}

.mx-54 {
  margin-left: 16.875rem;
  margin-right: 16.875rem;
}

.px-54 {
  padding-left: 16.875rem;
  padding-right: 16.875rem;
}

.mt-55 {
  margin-top: 17.1875rem;
}

.pt-55 {
  padding-top: 17.1875rem;
}

.mr-55 {
  margin-right: 17.1875rem;
}

.pr-55 {
  padding-right: 17.1875rem;
}

.mb-55 {
  margin-bottom: 17.1875rem;
}

.pb-55 {
  padding-bottom: 17.1875rem;
}

.ml-55 {
  margin-left: 17.1875rem;
}

.pl-55 {
  padding-left: 17.1875rem;
}

.m-55 {
  margin: 17.1875rem;
}

.p-55 {
  padding: 17.1875rem;
}

.my-55 {
  margin-top: 17.1875rem;
  margin-bottom: 17.1875rem;
}

.py-55 {
  padding-top: 17.1875rem;
  padding-bottom: 17.1875rem;
}

.mx-55 {
  margin-left: 17.1875rem;
  margin-right: 17.1875rem;
}

.px-55 {
  padding-left: 17.1875rem;
  padding-right: 17.1875rem;
}

.mt-56 {
  margin-top: 17.5rem;
}

.pt-56 {
  padding-top: 17.5rem;
}

.mr-56 {
  margin-right: 17.5rem;
}

.pr-56 {
  padding-right: 17.5rem;
}

.mb-56 {
  margin-bottom: 17.5rem;
}

.pb-56 {
  padding-bottom: 17.5rem;
}

.ml-56 {
  margin-left: 17.5rem;
}

.pl-56 {
  padding-left: 17.5rem;
}

.m-56 {
  margin: 17.5rem;
}

.p-56 {
  padding: 17.5rem;
}

.my-56 {
  margin-top: 17.5rem;
  margin-bottom: 17.5rem;
}

.py-56 {
  padding-top: 17.5rem;
  padding-bottom: 17.5rem;
}

.mx-56 {
  margin-left: 17.5rem;
  margin-right: 17.5rem;
}

.px-56 {
  padding-left: 17.5rem;
  padding-right: 17.5rem;
}

.mt-57 {
  margin-top: 17.8125rem;
}

.pt-57 {
  padding-top: 17.8125rem;
}

.mr-57 {
  margin-right: 17.8125rem;
}

.pr-57 {
  padding-right: 17.8125rem;
}

.mb-57 {
  margin-bottom: 17.8125rem;
}

.pb-57 {
  padding-bottom: 17.8125rem;
}

.ml-57 {
  margin-left: 17.8125rem;
}

.pl-57 {
  padding-left: 17.8125rem;
}

.m-57 {
  margin: 17.8125rem;
}

.p-57 {
  padding: 17.8125rem;
}

.my-57 {
  margin-top: 17.8125rem;
  margin-bottom: 17.8125rem;
}

.py-57 {
  padding-top: 17.8125rem;
  padding-bottom: 17.8125rem;
}

.mx-57 {
  margin-left: 17.8125rem;
  margin-right: 17.8125rem;
}

.px-57 {
  padding-left: 17.8125rem;
  padding-right: 17.8125rem;
}

.mt-58 {
  margin-top: 18.125rem;
}

.pt-58 {
  padding-top: 18.125rem;
}

.mr-58 {
  margin-right: 18.125rem;
}

.pr-58 {
  padding-right: 18.125rem;
}

.mb-58 {
  margin-bottom: 18.125rem;
}

.pb-58 {
  padding-bottom: 18.125rem;
}

.ml-58 {
  margin-left: 18.125rem;
}

.pl-58 {
  padding-left: 18.125rem;
}

.m-58 {
  margin: 18.125rem;
}

.p-58 {
  padding: 18.125rem;
}

.my-58 {
  margin-top: 18.125rem;
  margin-bottom: 18.125rem;
}

.py-58 {
  padding-top: 18.125rem;
  padding-bottom: 18.125rem;
}

.mx-58 {
  margin-left: 18.125rem;
  margin-right: 18.125rem;
}

.px-58 {
  padding-left: 18.125rem;
  padding-right: 18.125rem;
}

.mt-59 {
  margin-top: 18.4375rem;
}

.pt-59 {
  padding-top: 18.4375rem;
}

.mr-59 {
  margin-right: 18.4375rem;
}

.pr-59 {
  padding-right: 18.4375rem;
}

.mb-59 {
  margin-bottom: 18.4375rem;
}

.pb-59 {
  padding-bottom: 18.4375rem;
}

.ml-59 {
  margin-left: 18.4375rem;
}

.pl-59 {
  padding-left: 18.4375rem;
}

.m-59 {
  margin: 18.4375rem;
}

.p-59 {
  padding: 18.4375rem;
}

.my-59 {
  margin-top: 18.4375rem;
  margin-bottom: 18.4375rem;
}

.py-59 {
  padding-top: 18.4375rem;
  padding-bottom: 18.4375rem;
}

.mx-59 {
  margin-left: 18.4375rem;
  margin-right: 18.4375rem;
}

.px-59 {
  padding-left: 18.4375rem;
  padding-right: 18.4375rem;
}

.mt-60 {
  margin-top: 18.75rem;
}

.pt-60 {
  padding-top: 18.75rem;
}

.mr-60 {
  margin-right: 18.75rem;
}

.pr-60 {
  padding-right: 18.75rem;
}

.mb-60 {
  margin-bottom: 18.75rem;
}

.pb-60 {
  padding-bottom: 18.75rem;
}

.ml-60 {
  margin-left: 18.75rem;
}

.pl-60 {
  padding-left: 18.75rem;
}

.m-60 {
  margin: 18.75rem;
}

.p-60 {
  padding: 18.75rem;
}

.my-60 {
  margin-top: 18.75rem;
  margin-bottom: 18.75rem;
}

.py-60 {
  padding-top: 18.75rem;
  padding-bottom: 18.75rem;
}

.mx-60 {
  margin-left: 18.75rem;
  margin-right: 18.75rem;
}

.px-60 {
  padding-left: 18.75rem;
  padding-right: 18.75rem;
}

.mt-61 {
  margin-top: 19.0625rem;
}

.pt-61 {
  padding-top: 19.0625rem;
}

.mr-61 {
  margin-right: 19.0625rem;
}

.pr-61 {
  padding-right: 19.0625rem;
}

.mb-61 {
  margin-bottom: 19.0625rem;
}

.pb-61 {
  padding-bottom: 19.0625rem;
}

.ml-61 {
  margin-left: 19.0625rem;
}

.pl-61 {
  padding-left: 19.0625rem;
}

.m-61 {
  margin: 19.0625rem;
}

.p-61 {
  padding: 19.0625rem;
}

.my-61 {
  margin-top: 19.0625rem;
  margin-bottom: 19.0625rem;
}

.py-61 {
  padding-top: 19.0625rem;
  padding-bottom: 19.0625rem;
}

.mx-61 {
  margin-left: 19.0625rem;
  margin-right: 19.0625rem;
}

.px-61 {
  padding-left: 19.0625rem;
  padding-right: 19.0625rem;
}

.mt-62 {
  margin-top: 19.375rem;
}

.pt-62 {
  padding-top: 19.375rem;
}

.mr-62 {
  margin-right: 19.375rem;
}

.pr-62 {
  padding-right: 19.375rem;
}

.mb-62 {
  margin-bottom: 19.375rem;
}

.pb-62 {
  padding-bottom: 19.375rem;
}

.ml-62 {
  margin-left: 19.375rem;
}

.pl-62 {
  padding-left: 19.375rem;
}

.m-62 {
  margin: 19.375rem;
}

.p-62 {
  padding: 19.375rem;
}

.my-62 {
  margin-top: 19.375rem;
  margin-bottom: 19.375rem;
}

.py-62 {
  padding-top: 19.375rem;
  padding-bottom: 19.375rem;
}

.mx-62 {
  margin-left: 19.375rem;
  margin-right: 19.375rem;
}

.px-62 {
  padding-left: 19.375rem;
  padding-right: 19.375rem;
}

.mt-63 {
  margin-top: 19.6875rem;
}

.pt-63 {
  padding-top: 19.6875rem;
}

.mr-63 {
  margin-right: 19.6875rem;
}

.pr-63 {
  padding-right: 19.6875rem;
}

.mb-63 {
  margin-bottom: 19.6875rem;
}

.pb-63 {
  padding-bottom: 19.6875rem;
}

.ml-63 {
  margin-left: 19.6875rem;
}

.pl-63 {
  padding-left: 19.6875rem;
}

.m-63 {
  margin: 19.6875rem;
}

.p-63 {
  padding: 19.6875rem;
}

.my-63 {
  margin-top: 19.6875rem;
  margin-bottom: 19.6875rem;
}

.py-63 {
  padding-top: 19.6875rem;
  padding-bottom: 19.6875rem;
}

.mx-63 {
  margin-left: 19.6875rem;
  margin-right: 19.6875rem;
}

.px-63 {
  padding-left: 19.6875rem;
  padding-right: 19.6875rem;
}

.mt-64 {
  margin-top: 20rem;
}

.pt-64 {
  padding-top: 20rem;
}

.mr-64 {
  margin-right: 20rem;
}

.pr-64 {
  padding-right: 20rem;
}

.mb-64 {
  margin-bottom: 20rem;
}

.pb-64 {
  padding-bottom: 20rem;
}

.ml-64 {
  margin-left: 20rem;
}

.pl-64 {
  padding-left: 20rem;
}

.m-64 {
  margin: 20rem;
}

.p-64 {
  padding: 20rem;
}

.my-64 {
  margin-top: 20rem;
  margin-bottom: 20rem;
}

.py-64 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.mx-64 {
  margin-left: 20rem;
  margin-right: 20rem;
}

.px-64 {
  padding-left: 20rem;
  padding-right: 20rem;
}

.mt-65 {
  margin-top: 20.3125rem;
}

.pt-65 {
  padding-top: 20.3125rem;
}

.mr-65 {
  margin-right: 20.3125rem;
}

.pr-65 {
  padding-right: 20.3125rem;
}

.mb-65 {
  margin-bottom: 20.3125rem;
}

.pb-65 {
  padding-bottom: 20.3125rem;
}

.ml-65 {
  margin-left: 20.3125rem;
}

.pl-65 {
  padding-left: 20.3125rem;
}

.m-65 {
  margin: 20.3125rem;
}

.p-65 {
  padding: 20.3125rem;
}

.my-65 {
  margin-top: 20.3125rem;
  margin-bottom: 20.3125rem;
}

.py-65 {
  padding-top: 20.3125rem;
  padding-bottom: 20.3125rem;
}

.mx-65 {
  margin-left: 20.3125rem;
  margin-right: 20.3125rem;
}

.px-65 {
  padding-left: 20.3125rem;
  padding-right: 20.3125rem;
}

.mt-66 {
  margin-top: 20.625rem;
}

.pt-66 {
  padding-top: 20.625rem;
}

.mr-66 {
  margin-right: 20.625rem;
}

.pr-66 {
  padding-right: 20.625rem;
}

.mb-66 {
  margin-bottom: 20.625rem;
}

.pb-66 {
  padding-bottom: 20.625rem;
}

.ml-66 {
  margin-left: 20.625rem;
}

.pl-66 {
  padding-left: 20.625rem;
}

.m-66 {
  margin: 20.625rem;
}

.p-66 {
  padding: 20.625rem;
}

.my-66 {
  margin-top: 20.625rem;
  margin-bottom: 20.625rem;
}

.py-66 {
  padding-top: 20.625rem;
  padding-bottom: 20.625rem;
}

.mx-66 {
  margin-left: 20.625rem;
  margin-right: 20.625rem;
}

.px-66 {
  padding-left: 20.625rem;
  padding-right: 20.625rem;
}

.mt-67 {
  margin-top: 20.9375rem;
}

.pt-67 {
  padding-top: 20.9375rem;
}

.mr-67 {
  margin-right: 20.9375rem;
}

.pr-67 {
  padding-right: 20.9375rem;
}

.mb-67 {
  margin-bottom: 20.9375rem;
}

.pb-67 {
  padding-bottom: 20.9375rem;
}

.ml-67 {
  margin-left: 20.9375rem;
}

.pl-67 {
  padding-left: 20.9375rem;
}

.m-67 {
  margin: 20.9375rem;
}

.p-67 {
  padding: 20.9375rem;
}

.my-67 {
  margin-top: 20.9375rem;
  margin-bottom: 20.9375rem;
}

.py-67 {
  padding-top: 20.9375rem;
  padding-bottom: 20.9375rem;
}

.mx-67 {
  margin-left: 20.9375rem;
  margin-right: 20.9375rem;
}

.px-67 {
  padding-left: 20.9375rem;
  padding-right: 20.9375rem;
}

.mt-68 {
  margin-top: 21.25rem;
}

.pt-68 {
  padding-top: 21.25rem;
}

.mr-68 {
  margin-right: 21.25rem;
}

.pr-68 {
  padding-right: 21.25rem;
}

.mb-68 {
  margin-bottom: 21.25rem;
}

.pb-68 {
  padding-bottom: 21.25rem;
}

.ml-68 {
  margin-left: 21.25rem;
}

.pl-68 {
  padding-left: 21.25rem;
}

.m-68 {
  margin: 21.25rem;
}

.p-68 {
  padding: 21.25rem;
}

.my-68 {
  margin-top: 21.25rem;
  margin-bottom: 21.25rem;
}

.py-68 {
  padding-top: 21.25rem;
  padding-bottom: 21.25rem;
}

.mx-68 {
  margin-left: 21.25rem;
  margin-right: 21.25rem;
}

.px-68 {
  padding-left: 21.25rem;
  padding-right: 21.25rem;
}

.mt-69 {
  margin-top: 21.5625rem;
}

.pt-69 {
  padding-top: 21.5625rem;
}

.mr-69 {
  margin-right: 21.5625rem;
}

.pr-69 {
  padding-right: 21.5625rem;
}

.mb-69 {
  margin-bottom: 21.5625rem;
}

.pb-69 {
  padding-bottom: 21.5625rem;
}

.ml-69 {
  margin-left: 21.5625rem;
}

.pl-69 {
  padding-left: 21.5625rem;
}

.m-69 {
  margin: 21.5625rem;
}

.p-69 {
  padding: 21.5625rem;
}

.my-69 {
  margin-top: 21.5625rem;
  margin-bottom: 21.5625rem;
}

.py-69 {
  padding-top: 21.5625rem;
  padding-bottom: 21.5625rem;
}

.mx-69 {
  margin-left: 21.5625rem;
  margin-right: 21.5625rem;
}

.px-69 {
  padding-left: 21.5625rem;
  padding-right: 21.5625rem;
}

.mt-70 {
  margin-top: 21.875rem;
}

.pt-70 {
  padding-top: 21.875rem;
}

.mr-70 {
  margin-right: 21.875rem;
}

.pr-70 {
  padding-right: 21.875rem;
}

.mb-70 {
  margin-bottom: 21.875rem;
}

.pb-70 {
  padding-bottom: 21.875rem;
}

.ml-70 {
  margin-left: 21.875rem;
}

.pl-70 {
  padding-left: 21.875rem;
}

.m-70 {
  margin: 21.875rem;
}

.p-70 {
  padding: 21.875rem;
}

.my-70 {
  margin-top: 21.875rem;
  margin-bottom: 21.875rem;
}

.py-70 {
  padding-top: 21.875rem;
  padding-bottom: 21.875rem;
}

.mx-70 {
  margin-left: 21.875rem;
  margin-right: 21.875rem;
}

.px-70 {
  padding-left: 21.875rem;
  padding-right: 21.875rem;
}

.mt-71 {
  margin-top: 22.1875rem;
}

.pt-71 {
  padding-top: 22.1875rem;
}

.mr-71 {
  margin-right: 22.1875rem;
}

.pr-71 {
  padding-right: 22.1875rem;
}

.mb-71 {
  margin-bottom: 22.1875rem;
}

.pb-71 {
  padding-bottom: 22.1875rem;
}

.ml-71 {
  margin-left: 22.1875rem;
}

.pl-71 {
  padding-left: 22.1875rem;
}

.m-71 {
  margin: 22.1875rem;
}

.p-71 {
  padding: 22.1875rem;
}

.my-71 {
  margin-top: 22.1875rem;
  margin-bottom: 22.1875rem;
}

.py-71 {
  padding-top: 22.1875rem;
  padding-bottom: 22.1875rem;
}

.mx-71 {
  margin-left: 22.1875rem;
  margin-right: 22.1875rem;
}

.px-71 {
  padding-left: 22.1875rem;
  padding-right: 22.1875rem;
}

.mt-72 {
  margin-top: 22.5rem;
}

.pt-72 {
  padding-top: 22.5rem;
}

.mr-72 {
  margin-right: 22.5rem;
}

.pr-72 {
  padding-right: 22.5rem;
}

.mb-72 {
  margin-bottom: 22.5rem;
}

.pb-72 {
  padding-bottom: 22.5rem;
}

.ml-72 {
  margin-left: 22.5rem;
}

.pl-72 {
  padding-left: 22.5rem;
}

.m-72 {
  margin: 22.5rem;
}

.p-72 {
  padding: 22.5rem;
}

.my-72 {
  margin-top: 22.5rem;
  margin-bottom: 22.5rem;
}

.py-72 {
  padding-top: 22.5rem;
  padding-bottom: 22.5rem;
}

.mx-72 {
  margin-left: 22.5rem;
  margin-right: 22.5rem;
}

.px-72 {
  padding-left: 22.5rem;
  padding-right: 22.5rem;
}

.mt-73 {
  margin-top: 22.8125rem;
}

.pt-73 {
  padding-top: 22.8125rem;
}

.mr-73 {
  margin-right: 22.8125rem;
}

.pr-73 {
  padding-right: 22.8125rem;
}

.mb-73 {
  margin-bottom: 22.8125rem;
}

.pb-73 {
  padding-bottom: 22.8125rem;
}

.ml-73 {
  margin-left: 22.8125rem;
}

.pl-73 {
  padding-left: 22.8125rem;
}

.m-73 {
  margin: 22.8125rem;
}

.p-73 {
  padding: 22.8125rem;
}

.my-73 {
  margin-top: 22.8125rem;
  margin-bottom: 22.8125rem;
}

.py-73 {
  padding-top: 22.8125rem;
  padding-bottom: 22.8125rem;
}

.mx-73 {
  margin-left: 22.8125rem;
  margin-right: 22.8125rem;
}

.px-73 {
  padding-left: 22.8125rem;
  padding-right: 22.8125rem;
}

.mt-74 {
  margin-top: 23.125rem;
}

.pt-74 {
  padding-top: 23.125rem;
}

.mr-74 {
  margin-right: 23.125rem;
}

.pr-74 {
  padding-right: 23.125rem;
}

.mb-74 {
  margin-bottom: 23.125rem;
}

.pb-74 {
  padding-bottom: 23.125rem;
}

.ml-74 {
  margin-left: 23.125rem;
}

.pl-74 {
  padding-left: 23.125rem;
}

.m-74 {
  margin: 23.125rem;
}

.p-74 {
  padding: 23.125rem;
}

.my-74 {
  margin-top: 23.125rem;
  margin-bottom: 23.125rem;
}

.py-74 {
  padding-top: 23.125rem;
  padding-bottom: 23.125rem;
}

.mx-74 {
  margin-left: 23.125rem;
  margin-right: 23.125rem;
}

.px-74 {
  padding-left: 23.125rem;
  padding-right: 23.125rem;
}

.mt-75 {
  margin-top: 23.4375rem;
}

.pt-75 {
  padding-top: 23.4375rem;
}

.mr-75 {
  margin-right: 23.4375rem;
}

.pr-75 {
  padding-right: 23.4375rem;
}

.mb-75 {
  margin-bottom: 23.4375rem;
}

.pb-75 {
  padding-bottom: 23.4375rem;
}

.ml-75 {
  margin-left: 23.4375rem;
}

.pl-75 {
  padding-left: 23.4375rem;
}

.m-75 {
  margin: 23.4375rem;
}

.p-75 {
  padding: 23.4375rem;
}

.my-75 {
  margin-top: 23.4375rem;
  margin-bottom: 23.4375rem;
}

.py-75 {
  padding-top: 23.4375rem;
  padding-bottom: 23.4375rem;
}

.mx-75 {
  margin-left: 23.4375rem;
  margin-right: 23.4375rem;
}

.px-75 {
  padding-left: 23.4375rem;
  padding-right: 23.4375rem;
}

.mt-76 {
  margin-top: 23.75rem;
}

.pt-76 {
  padding-top: 23.75rem;
}

.mr-76 {
  margin-right: 23.75rem;
}

.pr-76 {
  padding-right: 23.75rem;
}

.mb-76 {
  margin-bottom: 23.75rem;
}

.pb-76 {
  padding-bottom: 23.75rem;
}

.ml-76 {
  margin-left: 23.75rem;
}

.pl-76 {
  padding-left: 23.75rem;
}

.m-76 {
  margin: 23.75rem;
}

.p-76 {
  padding: 23.75rem;
}

.my-76 {
  margin-top: 23.75rem;
  margin-bottom: 23.75rem;
}

.py-76 {
  padding-top: 23.75rem;
  padding-bottom: 23.75rem;
}

.mx-76 {
  margin-left: 23.75rem;
  margin-right: 23.75rem;
}

.px-76 {
  padding-left: 23.75rem;
  padding-right: 23.75rem;
}

.mt-77 {
  margin-top: 24.0625rem;
}

.pt-77 {
  padding-top: 24.0625rem;
}

.mr-77 {
  margin-right: 24.0625rem;
}

.pr-77 {
  padding-right: 24.0625rem;
}

.mb-77 {
  margin-bottom: 24.0625rem;
}

.pb-77 {
  padding-bottom: 24.0625rem;
}

.ml-77 {
  margin-left: 24.0625rem;
}

.pl-77 {
  padding-left: 24.0625rem;
}

.m-77 {
  margin: 24.0625rem;
}

.p-77 {
  padding: 24.0625rem;
}

.my-77 {
  margin-top: 24.0625rem;
  margin-bottom: 24.0625rem;
}

.py-77 {
  padding-top: 24.0625rem;
  padding-bottom: 24.0625rem;
}

.mx-77 {
  margin-left: 24.0625rem;
  margin-right: 24.0625rem;
}

.px-77 {
  padding-left: 24.0625rem;
  padding-right: 24.0625rem;
}

.mt-78 {
  margin-top: 24.375rem;
}

.pt-78 {
  padding-top: 24.375rem;
}

.mr-78 {
  margin-right: 24.375rem;
}

.pr-78 {
  padding-right: 24.375rem;
}

.mb-78 {
  margin-bottom: 24.375rem;
}

.pb-78 {
  padding-bottom: 24.375rem;
}

.ml-78 {
  margin-left: 24.375rem;
}

.pl-78 {
  padding-left: 24.375rem;
}

.m-78 {
  margin: 24.375rem;
}

.p-78 {
  padding: 24.375rem;
}

.my-78 {
  margin-top: 24.375rem;
  margin-bottom: 24.375rem;
}

.py-78 {
  padding-top: 24.375rem;
  padding-bottom: 24.375rem;
}

.mx-78 {
  margin-left: 24.375rem;
  margin-right: 24.375rem;
}

.px-78 {
  padding-left: 24.375rem;
  padding-right: 24.375rem;
}

.mt-79 {
  margin-top: 24.6875rem;
}

.pt-79 {
  padding-top: 24.6875rem;
}

.mr-79 {
  margin-right: 24.6875rem;
}

.pr-79 {
  padding-right: 24.6875rem;
}

.mb-79 {
  margin-bottom: 24.6875rem;
}

.pb-79 {
  padding-bottom: 24.6875rem;
}

.ml-79 {
  margin-left: 24.6875rem;
}

.pl-79 {
  padding-left: 24.6875rem;
}

.m-79 {
  margin: 24.6875rem;
}

.p-79 {
  padding: 24.6875rem;
}

.my-79 {
  margin-top: 24.6875rem;
  margin-bottom: 24.6875rem;
}

.py-79 {
  padding-top: 24.6875rem;
  padding-bottom: 24.6875rem;
}

.mx-79 {
  margin-left: 24.6875rem;
  margin-right: 24.6875rem;
}

.px-79 {
  padding-left: 24.6875rem;
  padding-right: 24.6875rem;
}

.mt-80 {
  margin-top: 25rem;
}

.pt-80 {
  padding-top: 25rem;
}

.mr-80 {
  margin-right: 25rem;
}

.pr-80 {
  padding-right: 25rem;
}

.mb-80 {
  margin-bottom: 25rem;
}

.pb-80 {
  padding-bottom: 25rem;
}

.ml-80 {
  margin-left: 25rem;
}

.pl-80 {
  padding-left: 25rem;
}

.m-80 {
  margin: 25rem;
}

.p-80 {
  padding: 25rem;
}

.my-80 {
  margin-top: 25rem;
  margin-bottom: 25rem;
}

.py-80 {
  padding-top: 25rem;
  padding-bottom: 25rem;
}

.mx-80 {
  margin-left: 25rem;
  margin-right: 25rem;
}

.px-80 {
  padding-left: 25rem;
  padding-right: 25rem;
}

.mt-81 {
  margin-top: 25.3125rem;
}

.pt-81 {
  padding-top: 25.3125rem;
}

.mr-81 {
  margin-right: 25.3125rem;
}

.pr-81 {
  padding-right: 25.3125rem;
}

.mb-81 {
  margin-bottom: 25.3125rem;
}

.pb-81 {
  padding-bottom: 25.3125rem;
}

.ml-81 {
  margin-left: 25.3125rem;
}

.pl-81 {
  padding-left: 25.3125rem;
}

.m-81 {
  margin: 25.3125rem;
}

.p-81 {
  padding: 25.3125rem;
}

.my-81 {
  margin-top: 25.3125rem;
  margin-bottom: 25.3125rem;
}

.py-81 {
  padding-top: 25.3125rem;
  padding-bottom: 25.3125rem;
}

.mx-81 {
  margin-left: 25.3125rem;
  margin-right: 25.3125rem;
}

.px-81 {
  padding-left: 25.3125rem;
  padding-right: 25.3125rem;
}

.mt-82 {
  margin-top: 25.625rem;
}

.pt-82 {
  padding-top: 25.625rem;
}

.mr-82 {
  margin-right: 25.625rem;
}

.pr-82 {
  padding-right: 25.625rem;
}

.mb-82 {
  margin-bottom: 25.625rem;
}

.pb-82 {
  padding-bottom: 25.625rem;
}

.ml-82 {
  margin-left: 25.625rem;
}

.pl-82 {
  padding-left: 25.625rem;
}

.m-82 {
  margin: 25.625rem;
}

.p-82 {
  padding: 25.625rem;
}

.my-82 {
  margin-top: 25.625rem;
  margin-bottom: 25.625rem;
}

.py-82 {
  padding-top: 25.625rem;
  padding-bottom: 25.625rem;
}

.mx-82 {
  margin-left: 25.625rem;
  margin-right: 25.625rem;
}

.px-82 {
  padding-left: 25.625rem;
  padding-right: 25.625rem;
}

.mt-83 {
  margin-top: 25.9375rem;
}

.pt-83 {
  padding-top: 25.9375rem;
}

.mr-83 {
  margin-right: 25.9375rem;
}

.pr-83 {
  padding-right: 25.9375rem;
}

.mb-83 {
  margin-bottom: 25.9375rem;
}

.pb-83 {
  padding-bottom: 25.9375rem;
}

.ml-83 {
  margin-left: 25.9375rem;
}

.pl-83 {
  padding-left: 25.9375rem;
}

.m-83 {
  margin: 25.9375rem;
}

.p-83 {
  padding: 25.9375rem;
}

.my-83 {
  margin-top: 25.9375rem;
  margin-bottom: 25.9375rem;
}

.py-83 {
  padding-top: 25.9375rem;
  padding-bottom: 25.9375rem;
}

.mx-83 {
  margin-left: 25.9375rem;
  margin-right: 25.9375rem;
}

.px-83 {
  padding-left: 25.9375rem;
  padding-right: 25.9375rem;
}

.mt-84 {
  margin-top: 26.25rem;
}

.pt-84 {
  padding-top: 26.25rem;
}

.mr-84 {
  margin-right: 26.25rem;
}

.pr-84 {
  padding-right: 26.25rem;
}

.mb-84 {
  margin-bottom: 26.25rem;
}

.pb-84 {
  padding-bottom: 26.25rem;
}

.ml-84 {
  margin-left: 26.25rem;
}

.pl-84 {
  padding-left: 26.25rem;
}

.m-84 {
  margin: 26.25rem;
}

.p-84 {
  padding: 26.25rem;
}

.my-84 {
  margin-top: 26.25rem;
  margin-bottom: 26.25rem;
}

.py-84 {
  padding-top: 26.25rem;
  padding-bottom: 26.25rem;
}

.mx-84 {
  margin-left: 26.25rem;
  margin-right: 26.25rem;
}

.px-84 {
  padding-left: 26.25rem;
  padding-right: 26.25rem;
}

.mt-85 {
  margin-top: 26.5625rem;
}

.pt-85 {
  padding-top: 26.5625rem;
}

.mr-85 {
  margin-right: 26.5625rem;
}

.pr-85 {
  padding-right: 26.5625rem;
}

.mb-85 {
  margin-bottom: 26.5625rem;
}

.pb-85 {
  padding-bottom: 26.5625rem;
}

.ml-85 {
  margin-left: 26.5625rem;
}

.pl-85 {
  padding-left: 26.5625rem;
}

.m-85 {
  margin: 26.5625rem;
}

.p-85 {
  padding: 26.5625rem;
}

.my-85 {
  margin-top: 26.5625rem;
  margin-bottom: 26.5625rem;
}

.py-85 {
  padding-top: 26.5625rem;
  padding-bottom: 26.5625rem;
}

.mx-85 {
  margin-left: 26.5625rem;
  margin-right: 26.5625rem;
}

.px-85 {
  padding-left: 26.5625rem;
  padding-right: 26.5625rem;
}

.mt-86 {
  margin-top: 26.875rem;
}

.pt-86 {
  padding-top: 26.875rem;
}

.mr-86 {
  margin-right: 26.875rem;
}

.pr-86 {
  padding-right: 26.875rem;
}

.mb-86 {
  margin-bottom: 26.875rem;
}

.pb-86 {
  padding-bottom: 26.875rem;
}

.ml-86 {
  margin-left: 26.875rem;
}

.pl-86 {
  padding-left: 26.875rem;
}

.m-86 {
  margin: 26.875rem;
}

.p-86 {
  padding: 26.875rem;
}

.my-86 {
  margin-top: 26.875rem;
  margin-bottom: 26.875rem;
}

.py-86 {
  padding-top: 26.875rem;
  padding-bottom: 26.875rem;
}

.mx-86 {
  margin-left: 26.875rem;
  margin-right: 26.875rem;
}

.px-86 {
  padding-left: 26.875rem;
  padding-right: 26.875rem;
}

.mt-87 {
  margin-top: 27.1875rem;
}

.pt-87 {
  padding-top: 27.1875rem;
}

.mr-87 {
  margin-right: 27.1875rem;
}

.pr-87 {
  padding-right: 27.1875rem;
}

.mb-87 {
  margin-bottom: 27.1875rem;
}

.pb-87 {
  padding-bottom: 27.1875rem;
}

.ml-87 {
  margin-left: 27.1875rem;
}

.pl-87 {
  padding-left: 27.1875rem;
}

.m-87 {
  margin: 27.1875rem;
}

.p-87 {
  padding: 27.1875rem;
}

.my-87 {
  margin-top: 27.1875rem;
  margin-bottom: 27.1875rem;
}

.py-87 {
  padding-top: 27.1875rem;
  padding-bottom: 27.1875rem;
}

.mx-87 {
  margin-left: 27.1875rem;
  margin-right: 27.1875rem;
}

.px-87 {
  padding-left: 27.1875rem;
  padding-right: 27.1875rem;
}

.mt-88 {
  margin-top: 27.5rem;
}

.pt-88 {
  padding-top: 27.5rem;
}

.mr-88 {
  margin-right: 27.5rem;
}

.pr-88 {
  padding-right: 27.5rem;
}

.mb-88 {
  margin-bottom: 27.5rem;
}

.pb-88 {
  padding-bottom: 27.5rem;
}

.ml-88 {
  margin-left: 27.5rem;
}

.pl-88 {
  padding-left: 27.5rem;
}

.m-88 {
  margin: 27.5rem;
}

.p-88 {
  padding: 27.5rem;
}

.my-88 {
  margin-top: 27.5rem;
  margin-bottom: 27.5rem;
}

.py-88 {
  padding-top: 27.5rem;
  padding-bottom: 27.5rem;
}

.mx-88 {
  margin-left: 27.5rem;
  margin-right: 27.5rem;
}

.px-88 {
  padding-left: 27.5rem;
  padding-right: 27.5rem;
}

.mt-89 {
  margin-top: 27.8125rem;
}

.pt-89 {
  padding-top: 27.8125rem;
}

.mr-89 {
  margin-right: 27.8125rem;
}

.pr-89 {
  padding-right: 27.8125rem;
}

.mb-89 {
  margin-bottom: 27.8125rem;
}

.pb-89 {
  padding-bottom: 27.8125rem;
}

.ml-89 {
  margin-left: 27.8125rem;
}

.pl-89 {
  padding-left: 27.8125rem;
}

.m-89 {
  margin: 27.8125rem;
}

.p-89 {
  padding: 27.8125rem;
}

.my-89 {
  margin-top: 27.8125rem;
  margin-bottom: 27.8125rem;
}

.py-89 {
  padding-top: 27.8125rem;
  padding-bottom: 27.8125rem;
}

.mx-89 {
  margin-left: 27.8125rem;
  margin-right: 27.8125rem;
}

.px-89 {
  padding-left: 27.8125rem;
  padding-right: 27.8125rem;
}

.mt-90 {
  margin-top: 28.125rem;
}

.pt-90 {
  padding-top: 28.125rem;
}

.mr-90 {
  margin-right: 28.125rem;
}

.pr-90 {
  padding-right: 28.125rem;
}

.mb-90 {
  margin-bottom: 28.125rem;
}

.pb-90 {
  padding-bottom: 28.125rem;
}

.ml-90 {
  margin-left: 28.125rem;
}

.pl-90 {
  padding-left: 28.125rem;
}

.m-90 {
  margin: 28.125rem;
}

.p-90 {
  padding: 28.125rem;
}

.my-90 {
  margin-top: 28.125rem;
  margin-bottom: 28.125rem;
}

.py-90 {
  padding-top: 28.125rem;
  padding-bottom: 28.125rem;
}

.mx-90 {
  margin-left: 28.125rem;
  margin-right: 28.125rem;
}

.px-90 {
  padding-left: 28.125rem;
  padding-right: 28.125rem;
}

.mt-91 {
  margin-top: 28.4375rem;
}

.pt-91 {
  padding-top: 28.4375rem;
}

.mr-91 {
  margin-right: 28.4375rem;
}

.pr-91 {
  padding-right: 28.4375rem;
}

.mb-91 {
  margin-bottom: 28.4375rem;
}

.pb-91 {
  padding-bottom: 28.4375rem;
}

.ml-91 {
  margin-left: 28.4375rem;
}

.pl-91 {
  padding-left: 28.4375rem;
}

.m-91 {
  margin: 28.4375rem;
}

.p-91 {
  padding: 28.4375rem;
}

.my-91 {
  margin-top: 28.4375rem;
  margin-bottom: 28.4375rem;
}

.py-91 {
  padding-top: 28.4375rem;
  padding-bottom: 28.4375rem;
}

.mx-91 {
  margin-left: 28.4375rem;
  margin-right: 28.4375rem;
}

.px-91 {
  padding-left: 28.4375rem;
  padding-right: 28.4375rem;
}

.mt-92 {
  margin-top: 28.75rem;
}

.pt-92 {
  padding-top: 28.75rem;
}

.mr-92 {
  margin-right: 28.75rem;
}

.pr-92 {
  padding-right: 28.75rem;
}

.mb-92 {
  margin-bottom: 28.75rem;
}

.pb-92 {
  padding-bottom: 28.75rem;
}

.ml-92 {
  margin-left: 28.75rem;
}

.pl-92 {
  padding-left: 28.75rem;
}

.m-92 {
  margin: 28.75rem;
}

.p-92 {
  padding: 28.75rem;
}

.my-92 {
  margin-top: 28.75rem;
  margin-bottom: 28.75rem;
}

.py-92 {
  padding-top: 28.75rem;
  padding-bottom: 28.75rem;
}

.mx-92 {
  margin-left: 28.75rem;
  margin-right: 28.75rem;
}

.px-92 {
  padding-left: 28.75rem;
  padding-right: 28.75rem;
}

.mt-93 {
  margin-top: 29.0625rem;
}

.pt-93 {
  padding-top: 29.0625rem;
}

.mr-93 {
  margin-right: 29.0625rem;
}

.pr-93 {
  padding-right: 29.0625rem;
}

.mb-93 {
  margin-bottom: 29.0625rem;
}

.pb-93 {
  padding-bottom: 29.0625rem;
}

.ml-93 {
  margin-left: 29.0625rem;
}

.pl-93 {
  padding-left: 29.0625rem;
}

.m-93 {
  margin: 29.0625rem;
}

.p-93 {
  padding: 29.0625rem;
}

.my-93 {
  margin-top: 29.0625rem;
  margin-bottom: 29.0625rem;
}

.py-93 {
  padding-top: 29.0625rem;
  padding-bottom: 29.0625rem;
}

.mx-93 {
  margin-left: 29.0625rem;
  margin-right: 29.0625rem;
}

.px-93 {
  padding-left: 29.0625rem;
  padding-right: 29.0625rem;
}

.mt-94 {
  margin-top: 29.375rem;
}

.pt-94 {
  padding-top: 29.375rem;
}

.mr-94 {
  margin-right: 29.375rem;
}

.pr-94 {
  padding-right: 29.375rem;
}

.mb-94 {
  margin-bottom: 29.375rem;
}

.pb-94 {
  padding-bottom: 29.375rem;
}

.ml-94 {
  margin-left: 29.375rem;
}

.pl-94 {
  padding-left: 29.375rem;
}

.m-94 {
  margin: 29.375rem;
}

.p-94 {
  padding: 29.375rem;
}

.my-94 {
  margin-top: 29.375rem;
  margin-bottom: 29.375rem;
}

.py-94 {
  padding-top: 29.375rem;
  padding-bottom: 29.375rem;
}

.mx-94 {
  margin-left: 29.375rem;
  margin-right: 29.375rem;
}

.px-94 {
  padding-left: 29.375rem;
  padding-right: 29.375rem;
}

.mt-95 {
  margin-top: 29.6875rem;
}

.pt-95 {
  padding-top: 29.6875rem;
}

.mr-95 {
  margin-right: 29.6875rem;
}

.pr-95 {
  padding-right: 29.6875rem;
}

.mb-95 {
  margin-bottom: 29.6875rem;
}

.pb-95 {
  padding-bottom: 29.6875rem;
}

.ml-95 {
  margin-left: 29.6875rem;
}

.pl-95 {
  padding-left: 29.6875rem;
}

.m-95 {
  margin: 29.6875rem;
}

.p-95 {
  padding: 29.6875rem;
}

.my-95 {
  margin-top: 29.6875rem;
  margin-bottom: 29.6875rem;
}

.py-95 {
  padding-top: 29.6875rem;
  padding-bottom: 29.6875rem;
}

.mx-95 {
  margin-left: 29.6875rem;
  margin-right: 29.6875rem;
}

.px-95 {
  padding-left: 29.6875rem;
  padding-right: 29.6875rem;
}

.mt-96 {
  margin-top: 30rem;
}

.pt-96 {
  padding-top: 30rem;
}

.mr-96 {
  margin-right: 30rem;
}

.pr-96 {
  padding-right: 30rem;
}

.mb-96 {
  margin-bottom: 30rem;
}

.pb-96 {
  padding-bottom: 30rem;
}

.ml-96 {
  margin-left: 30rem;
}

.pl-96 {
  padding-left: 30rem;
}

.m-96 {
  margin: 30rem;
}

.p-96 {
  padding: 30rem;
}

.my-96 {
  margin-top: 30rem;
  margin-bottom: 30rem;
}

.py-96 {
  padding-top: 30rem;
  padding-bottom: 30rem;
}

.mx-96 {
  margin-left: 30rem;
  margin-right: 30rem;
}

.px-96 {
  padding-left: 30rem;
  padding-right: 30rem;
}

.mt-97 {
  margin-top: 30.3125rem;
}

.pt-97 {
  padding-top: 30.3125rem;
}

.mr-97 {
  margin-right: 30.3125rem;
}

.pr-97 {
  padding-right: 30.3125rem;
}

.mb-97 {
  margin-bottom: 30.3125rem;
}

.pb-97 {
  padding-bottom: 30.3125rem;
}

.ml-97 {
  margin-left: 30.3125rem;
}

.pl-97 {
  padding-left: 30.3125rem;
}

.m-97 {
  margin: 30.3125rem;
}

.p-97 {
  padding: 30.3125rem;
}

.my-97 {
  margin-top: 30.3125rem;
  margin-bottom: 30.3125rem;
}

.py-97 {
  padding-top: 30.3125rem;
  padding-bottom: 30.3125rem;
}

.mx-97 {
  margin-left: 30.3125rem;
  margin-right: 30.3125rem;
}

.px-97 {
  padding-left: 30.3125rem;
  padding-right: 30.3125rem;
}

.mt-98 {
  margin-top: 30.625rem;
}

.pt-98 {
  padding-top: 30.625rem;
}

.mr-98 {
  margin-right: 30.625rem;
}

.pr-98 {
  padding-right: 30.625rem;
}

.mb-98 {
  margin-bottom: 30.625rem;
}

.pb-98 {
  padding-bottom: 30.625rem;
}

.ml-98 {
  margin-left: 30.625rem;
}

.pl-98 {
  padding-left: 30.625rem;
}

.m-98 {
  margin: 30.625rem;
}

.p-98 {
  padding: 30.625rem;
}

.my-98 {
  margin-top: 30.625rem;
  margin-bottom: 30.625rem;
}

.py-98 {
  padding-top: 30.625rem;
  padding-bottom: 30.625rem;
}

.mx-98 {
  margin-left: 30.625rem;
  margin-right: 30.625rem;
}

.px-98 {
  padding-left: 30.625rem;
  padding-right: 30.625rem;
}

.mt-99 {
  margin-top: 30.9375rem;
}

.pt-99 {
  padding-top: 30.9375rem;
}

.mr-99 {
  margin-right: 30.9375rem;
}

.pr-99 {
  padding-right: 30.9375rem;
}

.mb-99 {
  margin-bottom: 30.9375rem;
}

.pb-99 {
  padding-bottom: 30.9375rem;
}

.ml-99 {
  margin-left: 30.9375rem;
}

.pl-99 {
  padding-left: 30.9375rem;
}

.m-99 {
  margin: 30.9375rem;
}

.p-99 {
  padding: 30.9375rem;
}

.my-99 {
  margin-top: 30.9375rem;
  margin-bottom: 30.9375rem;
}

.py-99 {
  padding-top: 30.9375rem;
  padding-bottom: 30.9375rem;
}

.mx-99 {
  margin-left: 30.9375rem;
  margin-right: 30.9375rem;
}

.px-99 {
  padding-left: 30.9375rem;
  padding-right: 30.9375rem;
}

.mt-100 {
  margin-top: 31.25rem;
}

.pt-100 {
  padding-top: 31.25rem;
}

.mr-100 {
  margin-right: 31.25rem;
}

.pr-100 {
  padding-right: 31.25rem;
}

.mb-100 {
  margin-bottom: 31.25rem;
}

.pb-100 {
  padding-bottom: 31.25rem;
}

.ml-100 {
  margin-left: 31.25rem;
}

.pl-100 {
  padding-left: 31.25rem;
}

.m-100 {
  margin: 31.25rem;
}

.p-100 {
  padding: 31.25rem;
}

.my-100 {
  margin-top: 31.25rem;
  margin-bottom: 31.25rem;
}

.py-100 {
  padding-top: 31.25rem;
  padding-bottom: 31.25rem;
}

.mx-100 {
  margin-left: 31.25rem;
  margin-right: 31.25rem;
}

.px-100 {
  padding-left: 31.25rem;
  padding-right: 31.25rem;
}

.h-0 {
  height: 0%;
}

.w-0 {
  width: 0%;
}

.h-10 {
  height: 10%;
}

.w-10 {
  width: 10%;
}

.h-20 {
  height: 20%;
}

.w-20 {
  width: 20%;
}

.h-30 {
  height: 30%;
}

.w-30 {
  width: 30%;
}

.h-40 {
  height: 40%;
}

.w-40 {
  width: 40%;
}

.h-50 {
  height: 50%;
}

.w-50 {
  width: 50%;
}

.h-60 {
  height: 60%;
}

.w-60 {
  width: 60%;
}

.h-70 {
  height: 70%;
}

.w-70 {
  width: 70%;
}

.h-80 {
  height: 80%;
}

.w-80 {
  width: 80%;
}

.h-90 {
  height: 90%;
}

.w-90 {
  width: 90%;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

@font-face {
  font-family: socicon;
  src: url("../fonts/socicon.eot");
  src: url("../fonts/socicon.eot?#iefix") format("embedded-opentype"), url("../fonts/socicon.woff") format("woff"), url("../fonts/socicon.ttf") format("truetype"), url("../fonts/socicon.svg#sociconregular") format("svg");
  font-weight: normal;
  font-style: normal;
  text-transform: initial;
}
.socicon::before,
[class^=socicon-]::before,
[class*=" socicon-"]::before {
  display: inline-block;
  font-family: socicon;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.socicon-500px::before {
  content: "\e000";
}

.socicon-8tracks::before {
  content: "\e001";
}

.socicon-airbnb::before {
  content: "\e002";
}

.socicon-alliance::before {
  content: "\e003";
}

.socicon-amazon::before {
  content: "\e004";
}

.socicon-amplement::before {
  content: "\e005";
}

.socicon-android::before {
  content: "\e006";
}

.socicon-angellist::before {
  content: "\e007";
}

.socicon-apple::before {
  content: "\e008";
}

.socicon-appnet::before {
  content: "\e009";
}

.socicon-baidu::before {
  content: "\e00a";
}

.socicon-bandcamp::before {
  content: "\e00b";
}

.socicon-battlenet::before {
  content: "\e00c";
}

.socicon-beam::before {
  content: "\e00d";
}

.socicon-bebee::before {
  content: "\e00e";
}

.socicon-bebo::before {
  content: "\e00f";
}

.socicon-behance::before {
  content: "\e010";
}

.socicon-blizzard::before {
  content: "\e011";
}

.socicon-blogger::before {
  content: "\e012";
}

.socicon-buffer::before {
  content: "\e013";
}

.socicon-chrome::before {
  content: "\e014";
}

.socicon-coderwall::before {
  content: "\e015";
}

.socicon-curse::before {
  content: "\e016";
}

.socicon-dailymotion::before {
  content: "\e017";
}

.socicon-deezer::before {
  content: "\e018";
}

.socicon-delicious::before {
  content: "\e019";
}

.socicon-deviantart::before {
  content: "\e01a";
}

.socicon-diablo::before {
  content: "\e01b";
}

.socicon-digg::before {
  content: "\e01c";
}

.socicon-discord::before {
  content: "\e01d";
}

.socicon-disqus::before {
  content: "\e01e";
}

.socicon-douban::before {
  content: "\e01f";
}

.socicon-draugiem::before {
  content: "\e020";
}

.socicon-dribbble::before {
  content: "\e021";
}

.socicon-drupal::before {
  content: "\e022";
}

.socicon-ebay::before {
  content: "\e023";
}

.socicon-ello::before {
  content: "\e024";
}

.socicon-endomodo::before {
  content: "\e025";
}

.socicon-envato::before {
  content: "\e026";
}

.socicon-etsy::before {
  content: "\e027";
}

.socicon-facebook::before {
  content: "\e028";
}

.socicon-feedburner::before {
  content: "\e029";
}

.socicon-filmweb::before {
  content: "\e02a";
}

.socicon-firefox::before {
  content: "\e02b";
}

.socicon-flattr::before {
  content: "\e02c";
}

.socicon-flickr::before {
  content: "\e02d";
}

.socicon-formulr::before {
  content: "\e02e";
}

.socicon-forrst::before {
  content: "\e02f";
}

.socicon-foursquare::before {
  content: "\e030";
}

.socicon-friendfeed::before {
  content: "\e031";
}

.socicon-github::before {
  content: "\e032";
}

.socicon-goodreads::before {
  content: "\e033";
}

.socicon-google::before {
  content: "\e034";
}

.socicon-google-scholar::before {
  content: "\e035";
}

.socicon-googlegroups::before {
  content: "\e036";
}

.socicon-googlephotos::before {
  content: "\e037";
}

.socicon-googleplus::before {
  content: "\e038";
}

.socicon-grooveshark::before {
  content: "\e039";
}

.socicon-hackerrank::before {
  content: "\e03a";
}

.socicon-hearthstone::before {
  content: "\e03b";
}

.socicon-hellocoton::before {
  content: "\e03c";
}

.socicon-heroes::before {
  content: "\e03d";
}

.socicon-hitbox::before {
  content: "\e03e";
}

.socicon-horde::before {
  content: "\e03f";
}

.socicon-houzz::before {
  content: "\e040";
}

.socicon-icq::before {
  content: "\e041";
}

.socicon-identica::before {
  content: "\e042";
}

.socicon-imdb::before {
  content: "\e043";
}

.socicon-instagram::before {
  content: "\e044";
}

.socicon-issuu::before {
  content: "\e045";
}

.socicon-istock::before {
  content: "\e046";
}

.socicon-itunes::before {
  content: "\e047";
}

.socicon-keybase::before {
  content: "\e048";
}

.socicon-lanyrd::before {
  content: "\e049";
}

.socicon-lastfm::before {
  content: "\e04a";
}

.socicon-line::before {
  content: "\e04b";
}

.socicon-linkedin::before {
  content: "\e04c";
}

.socicon-livejournal::before {
  content: "\e04d";
}

.socicon-lyft::before {
  content: "\e04e";
}

.socicon-macos::before {
  content: "\e04f";
}

.socicon-mail::before {
  content: "\e050";
}

.socicon-medium::before {
  content: "\e051";
}

.socicon-meetup::before {
  content: "\e052";
}

.socicon-mixcloud::before {
  content: "\e053";
}

.socicon-modelmayhem::before {
  content: "\e054";
}

.socicon-mumble::before {
  content: "\e055";
}

.socicon-myspace::before {
  content: "\e056";
}

.socicon-newsvine::before {
  content: "\e057";
}

.socicon-nintendo::before {
  content: "\e058";
}

.socicon-npm::before {
  content: "\e059";
}

.socicon-odnoklassniki::before {
  content: "\e05a";
}

.socicon-openid::before {
  content: "\e05b";
}

.socicon-opera::before {
  content: "\e05c";
}

.socicon-outlook::before {
  content: "\e05d";
}

.socicon-overwatch::before {
  content: "\e05e";
}

.socicon-patreon::before {
  content: "\e05f";
}

.socicon-paypal::before {
  content: "\e060";
}

.socicon-periscope::before {
  content: "\e061";
}

.socicon-persona::before {
  content: "\e062";
}

.socicon-pinterest::before {
  content: "\e063";
}

.socicon-play::before {
  content: "\e064";
}

.socicon-player::before {
  content: "\e065";
}

.socicon-playstation::before {
  content: "\e066";
}

.socicon-pocket::before {
  content: "\e067";
}

.socicon-qq::before {
  content: "\e068";
}

.socicon-quora::before {
  content: "\e069";
}

.socicon-raidcall::before {
  content: "\e06a";
}

.socicon-ravelry::before {
  content: "\e06b";
}

.socicon-reddit::before {
  content: "\e06c";
}

.socicon-renren::before {
  content: "\e06d";
}

.socicon-researchgate::before {
  content: "\e06e";
}

.socicon-residentadvisor::before {
  content: "\e06f";
}

.socicon-reverbnation::before {
  content: "\e070";
}

.socicon-rss::before {
  content: "\e071";
}

.socicon-sharethis::before {
  content: "\e072";
}

.socicon-skype::before {
  content: "\e073";
}

.socicon-slideshare::before {
  content: "\e074";
}

.socicon-smugmug::before {
  content: "\e075";
}

.socicon-snapchat::before {
  content: "\e076";
}

.socicon-songkick::before {
  content: "\e077";
}

.socicon-soundcloud::before {
  content: "\e078";
}

.socicon-spotify::before {
  content: "\e079";
}

.socicon-stackexchange::before {
  content: "\e07a";
}

.socicon-stackoverflow::before {
  content: "\e07b";
}

.socicon-starcraft::before {
  content: "\e07c";
}

.socicon-stayfriends::before {
  content: "\e07d";
}

.socicon-steam::before {
  content: "\e07e";
}

.socicon-storehouse::before {
  content: "\e07f";
}

.socicon-strava::before {
  content: "\e080";
}

.socicon-streamjar::before {
  content: "\e081";
}

.socicon-stumbleupon::before {
  content: "\e082";
}

.socicon-swarm::before {
  content: "\e083";
}

.socicon-teamspeak::before {
  content: "\e084";
}

.socicon-teamviewer::before {
  content: "\e085";
}

.socicon-technorati::before {
  content: "\e086";
}

.socicon-telegram::before {
  content: "\e087";
}

.socicon-tripadvisor::before {
  content: "\e088";
}

.socicon-tripit::before {
  content: "\e089";
}

.socicon-triplej::before {
  content: "\e08a";
}

.socicon-tumblr::before {
  content: "\e08b";
}

.socicon-twitch::before {
  content: "\e08c";
}

.socicon-twitter::before {
  content: "\e08d";
}

.socicon-uber::before {
  content: "\e08e";
}

.socicon-ventrilo::before {
  content: "\e08f";
}

.socicon-viadeo::before {
  content: "\e090";
}

.socicon-viber::before {
  content: "\e091";
}

.socicon-viewbug::before {
  content: "\e092";
}

.socicon-vimeo::before {
  content: "\e093";
}

.socicon-vine::before {
  content: "\e094";
}

.socicon-vkontakte::before {
  content: "\e095";
}

.socicon-warcraft::before {
  content: "\e096";
}

.socicon-wechat::before {
  content: "\e097";
}

.socicon-weibo::before {
  content: "\e098";
}

.socicon-whatsapp::before {
  content: "\e099";
}

.socicon-wikipedia::before {
  content: "\e09a";
}

.socicon-windows::before {
  content: "\e09b";
}

.socicon-wordpress::before {
  content: "\e09c";
}

.socicon-wykop::before {
  content: "\e09d";
}

.socicon-xbox::before {
  content: "\e09e";
}

.socicon-xing::before {
  content: "\e09f";
}

.socicon-yahoo::before {
  content: "\e0a0";
}

.socicon-yammer::before {
  content: "\e0a1";
}

.socicon-yandex::before {
  content: "\e0a2";
}

.socicon-yelp::before {
  content: "\e0a3";
}

.socicon-younow::before {
  content: "\e0a4";
}

.socicon-youtube::before {
  content: "\e0a5";
}

.socicon-zapier::before {
  content: "\e0a6";
}

.socicon-zerply::before {
  content: "\e0a7";
}

.socicon-zomato::before {
  content: "\e0a8";
}

.socicon-zynga::before {
  content: "\e0a9";
}

html {
  font-size: 16px;
}
@media (width <= 1680px) {
  html {
    font-size: 13.5px;
  }
}
@media (width <= 1280px) {
  html {
    font-size: 12.5px;
  }
}
@media (width <= 990px) {
  html {
    font-size: 2vw;
  }
}
@media (width <= 768px) {
  html {
    font-size: 3vw;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.text-blue {
  color: #2291ff;
}

.contact-form {
  padding: 10rem 0;
}
@media (width <= 990px) {
  .contact-form {
    padding: 6rem 0;
  }
}
@media (width <= 990px) and (width <= 990px) {
  .contact-form {
    padding: 6rem 0 1rem;
  }
}
@media (width <= 990px) {
  .contact-form #contact-form {
    margin-bottom: 5rem;
  }
}
.contact-form .contact-title {
  font-family: Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 800;
  margin-bottom: 2.5rem;
  font-size: 2rem;
}
.contact-form .form-group {
  margin-bottom: 1.875rem;
}
.contact-form .form-group .form-control {
  font-family: Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  font-weight: 500;
  font-size: 0.875rem;
}
.contact-form .form-group .form-control.error {
  border-color: #e42c2a;
  box-shadow: 0 0 5px rgba(228, 44, 42, 0.5);
}
.contact-form .info-slot {
  margin-bottom: 2.2rem;
  color: #575757;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (width <= 990px) {
  .contact-form .info-slot {
    justify-content: flex-start;
  }
}
.contact-form .info-slot .icon {
  padding-right: 0.7rem;
  font-size: 6.5rem;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
}
.contact-form .info-slot .text {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
}
.contact-form .info-slot .text address {
  margin: 0;
}
.contact-form .info-slot .text a {
  color: #575757;
  transition: color 0.2 ease;
}
.contact-form .info-slot .text a:hover {
  color: #e42c2a;
}
.contact-form .info-slot .text li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contact-form .privacy-policy {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.contact-form .privacy-policy .form-group {
  margin: 0;
  margin-top: -0.3rem;
}
.contact-form .privacy-policy a {
  color: #575757;
  text-decoration: underline;
  transition: color 0.2 ease;
}
.contact-form .privacy-policy a:hover {
  color: #e42c2a;
}
.contact-form .result-text {
  margin-top: 1rem;
}

@media (width <= 990px) {
  .double-column.reverse .row {
    flex-direction: column-reverse;
  }
}

.error .container {
  margin: 7rem auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.footer {
  background: #212121;
}
.footer h4,
.footer h5,
.footer span,
.footer p {
  color: #a8a8a8;
}
.footer .content {
  display: flex;
  flex-wrap: wrap;
  padding: 6rem 0 3rem;
}
@media (width <= 990px) {
  .footer .content {
    padding: 3rem 0 0;
    text-align: center;
  }
}
.footer .content h4 {
  font-size: 1.5rem;
  margin-top: 10px;
}
@media (width <= 990px) {
  .footer .content h4 {
    margin-top: 30px;
  }
}
.footer .content h5 {
  font-size: 1.2rem;
}
.footer .content .links {
  width: auto;
  padding: 0;
  margin: 0;
  text-align: right;
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 0.5rem;
  font-size: 16px;
}
@media (width <= 990px) {
  .footer .content .links {
    display: none;
  }
}
.footer .content .links li a span {
  text-transform: uppercase;
  transition: color 0.25s ease-out !important;
}
.footer .content .links li a:hover span, .footer .content .links li a.active span {
  color: #e42c2a;
}
.footer .icon-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding-bottom: 2rem;
  flex-wrap: wrap;
}
@media (width <= 990px) {
  .footer .icon-row {
    flex-direction: column-reverse;
  }
}
.footer .icon-row .awards {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 0;
}
@media (width <= 990px) {
  .footer .icon-row .awards {
    justify-content: center;
  }
}
.footer .icon-row .awards img {
  width: 3rem;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.footer .icon-row .socials {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
}
@media (width <= 990px) {
  .footer .icon-row .socials {
    gap: 2rem;
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    text-align: center;
    justify-content: center;
    border-bottom: 1px solid #7f7f7f;
  }
}
.footer .icon-row .socials img {
  width: 1.8rem;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  transition: opacity 0.2s ease-in-out;
}
@media (width <= 990px) {
  .footer .icon-row .socials img {
    width: 2.2rem;
  }
}
.footer .icon-row .socials img:hover {
  cursor: pointer;
  opacity: 0.8;
}
.footer .bottom-bar .inner {
  border-top: 1px #7f7f7f solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}
@media (width <= 990px) {
  .footer .bottom-bar .inner {
    flex-direction: column;
    gap: 0;
  }
}
.footer .bottom-bar .inner p {
  font-size: 0.875rem;
  font-weight: 300;
  margin: 0;
}
@media (width <= 990px) {
  .footer .bottom-bar .inner span {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.footer .bottom-bar .inner .links {
  display: flex;
  gap: 2rem;
}
@media (width <= 990px) {
  .footer .bottom-bar .inner .links {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.footer .bottom-bar .inner .links .pixel-logo {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 5.3125rem;
}
@media (width <= 990px) {
  .footer .bottom-bar .inner .links .pixel-logo {
    width: 6.25rem;
  }
}
.footer .bottom-bar .inner .links .link {
  position: relative;
  color: #7f7f7f;
  transition: color 0.25s ease-out !important;
  line-height: 16px;
}
.footer .bottom-bar .inner .links .link:hover {
  color: #e42c2a;
}

.full-text {
  text-align: center;
}
.full-text.iubenda {
  text-align: left;
}

@media (width <= 990px) {
  .main-header .navbar .navbar-collapse {
    box-shadow: none;
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  .main-header .navbar .navbar-collapse.show {
    max-height: 80vh;
    overflow-y: auto;
    transition: max-height 0.3s ease-in-out;
  }
  .main-header .navbar .navbar-nav {
    margin-top: 1.5rem;
    justify-content: space-between;
  }
  .main-header .navbar .navbar-nav li a {
    padding: 1rem 1.1375rem !important;
    font-size: 1.2rem !important;
  }
}
.main-header .navbar .navbar-toggler {
  padding: 0;
  top: 0;
  margin-top: 0;
  outline: none;
  cursor: pointer;
  transform: translateY(50%);
}
.main-header .navbar .navbar-brand img {
  width: 95px;
  height: auto;
}
@media (width <= 990px) {
  .main-header .navbar .navbar-brand img {
    width: 55px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-20%, 5%);
  }
}
.main-header .navbar .navbar-nav a {
  cursor: pointer;
}
.main-header .navbar .navbar-nav a:hover {
  color: #e42c2a !important;
}
.main-header .navbar .navbar-nav a.active {
  color: #e42c2a !important;
}

.hero {
  height: 100vh;
  width: 100%;
  position: relative;
}
.hero::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--filter-color);
  z-index: 10;
}
.hero .video {
  --videoRatio: calc(16 / 9);
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.hero .video iframe {
  --w: 100vw;
  --h: calc(var(--w) / var(--videoRatio));
  padding: 0;
  margin: 0;
  position: relative;
  top: calc(50% - var(--h) / 2);
  left: 0;
  width: var(--w);
  height: var(--h);
}
@media (aspect-ratio <= 16/9) {
  .hero .video iframe {
    --h: 100vh;
    --w: calc(var(--h) * var(--videoRatio));
    top: 0;
    left: calc(50% - var(--w) / 2);
  }
}
.hero .video video {
  height: 100vh;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.hero .cover {
  height: 100vh;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
.hero::after {
  content: none;
}

.lazy-load * {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.lazy-load .lazy-item * {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.lazy-load .lazy-item:nth-child(1n) {
  transition-delay: 0.15s;
}
.lazy-load .lazy-item:nth-child(2n) {
  transition-delay: 0.3s;
}
.lazy-load .lazy-item:nth-child(3n) {
  transition-delay: 0.45s;
}
.lazy-load .lazy-item:nth-child(4n) {
  transition-delay: 0.6s;
}
.lazy-load .lazy-item:nth-child(5n) {
  transition-delay: 0.75s;
}
.lazy-load .lazy-item:nth-child(6n) {
  transition-delay: 0.9s;
}
.lazy-load .lazy-item:nth-child(7n) {
  transition-delay: 1.05s;
}
.lazy-load .lazy-item:nth-child(8n) {
  transition-delay: 1.2s;
}
.lazy-load .lazy-item:nth-child(9n) {
  transition-delay: 1.35s;
}
.lazy-load .lazy-item:nth-child(10n) {
  transition-delay: 1.5s;
}
.lazy-load .lazy-item:nth-child(11n) {
  transition-delay: 1.65s;
}
.lazy-load .lazy-item:nth-child(12n) {
  transition-delay: 1.8s;
}
.lazy-load .lazy-item:nth-child(13n) {
  transition-delay: 1.95s;
}
.lazy-load .lazy-item:nth-child(14n) {
  transition-delay: 2.1s;
}
.lazy-load .lazy-item:nth-child(15n) {
  transition-delay: 2.25s;
}
.lazy-load .lazy-item:nth-child(16n) {
  transition-delay: 2.4s;
}
.lazy-load .lazy-item:nth-child(17n) {
  transition-delay: 2.55s;
}
.lazy-load .lazy-item:nth-child(18n) {
  transition-delay: 2.7s;
}
.lazy-load .lazy-item:nth-child(19n) {
  transition-delay: 2.85s;
}
.lazy-load .lazy-item:nth-child(20n) {
  transition-delay: 3s;
}
.lazy-load .lazy-item:nth-child(21n) {
  transition-delay: 3.15s;
}
.lazy-load .lazy-item:nth-child(22n) {
  transition-delay: 3.3s;
}
.lazy-load .lazy-item:nth-child(23n) {
  transition-delay: 3.45s;
}
.lazy-load .lazy-item:nth-child(24n) {
  transition-delay: 3.6s;
}
.lazy-load .lazy-item:nth-child(25n) {
  transition-delay: 3.75s;
}
.lazy-load .lazy-item:nth-child(26n) {
  transition-delay: 3.9s;
}
.lazy-load .lazy-item:nth-child(27n) {
  transition-delay: 4.05s;
}
.lazy-load .lazy-item:nth-child(28n) {
  transition-delay: 4.2s;
}
.lazy-load .lazy-item:nth-child(29n) {
  transition-delay: 4.35s;
}
.lazy-load .lazy-item:nth-child(30n) {
  transition-delay: 4.5s;
}
.lazy-load .lazy-item:nth-child(31n) {
  transition-delay: 4.65s;
}
.lazy-load .lazy-item:nth-child(32n) {
  transition-delay: 4.8s;
}
.lazy-load .lazy-item:nth-child(33n) {
  transition-delay: 4.95s;
}
.lazy-load .lazy-item:nth-child(34n) {
  transition-delay: 5.1s;
}
.lazy-load .lazy-item:nth-child(35n) {
  transition-delay: 5.25s;
}
.lazy-load .lazy-item:nth-child(36n) {
  transition-delay: 5.4s;
}
.lazy-load .lazy-item:nth-child(37n) {
  transition-delay: 5.55s;
}
.lazy-load .lazy-item:nth-child(38n) {
  transition-delay: 5.7s;
}
.lazy-load .lazy-item:nth-child(39n) {
  transition-delay: 5.85s;
}
.lazy-load .lazy-item:nth-child(40n) {
  transition-delay: 6s;
}
.lazy-load .lazy-item:nth-child(41n) {
  transition-delay: 6.15s;
}
.lazy-load .lazy-item:nth-child(42n) {
  transition-delay: 6.3s;
}
.lazy-load .lazy-item:nth-child(43n) {
  transition-delay: 6.45s;
}
.lazy-load .lazy-item:nth-child(44n) {
  transition-delay: 6.6s;
}
.lazy-load .lazy-item:nth-child(45n) {
  transition-delay: 6.75s;
}
.lazy-load .lazy-item:nth-child(46n) {
  transition-delay: 6.9s;
}
.lazy-load .lazy-item:nth-child(47n) {
  transition-delay: 7.05s;
}
.lazy-load .lazy-item:nth-child(48n) {
  transition-delay: 7.2s;
}
.lazy-load .lazy-item:nth-child(49n) {
  transition-delay: 7.35s;
}
.lazy-load .lazy-item:nth-child(50n) {
  transition-delay: 7.5s;
}
.lazy-load .lazy-item:nth-child(51n) {
  transition-delay: 7.65s;
}
.lazy-load .lazy-item:nth-child(52n) {
  transition-delay: 7.8s;
}
.lazy-load .lazy-item:nth-child(53n) {
  transition-delay: 7.95s;
}
.lazy-load .lazy-item:nth-child(54n) {
  transition-delay: 8.1s;
}
.lazy-load .lazy-item:nth-child(55n) {
  transition-delay: 8.25s;
}
.lazy-load .lazy-item:nth-child(56n) {
  transition-delay: 8.4s;
}
.lazy-load .lazy-item:nth-child(57n) {
  transition-delay: 8.55s;
}
.lazy-load .lazy-item:nth-child(58n) {
  transition-delay: 8.7s;
}
.lazy-load .lazy-item:nth-child(59n) {
  transition-delay: 8.85s;
}
.lazy-load .lazy-item:nth-child(60n) {
  transition-delay: 9s;
}
.lazy-load .lazy-item:nth-child(61n) {
  transition-delay: 9.15s;
}
.lazy-load .lazy-item:nth-child(62n) {
  transition-delay: 9.3s;
}
.lazy-load .lazy-item:nth-child(63n) {
  transition-delay: 9.45s;
}
.lazy-load .lazy-item:nth-child(64n) {
  transition-delay: 9.6s;
}
.lazy-load .lazy-item:nth-child(65n) {
  transition-delay: 9.75s;
}
.lazy-load .lazy-item:nth-child(66n) {
  transition-delay: 9.9s;
}
.lazy-load .lazy-item:nth-child(67n) {
  transition-delay: 10.05s;
}
.lazy-load .lazy-item:nth-child(68n) {
  transition-delay: 10.2s;
}
.lazy-load .lazy-item:nth-child(69n) {
  transition-delay: 10.35s;
}
.lazy-load .lazy-item:nth-child(70n) {
  transition-delay: 10.5s;
}
.lazy-load .lazy-item:nth-child(71n) {
  transition-delay: 10.65s;
}
.lazy-load .lazy-item:nth-child(72n) {
  transition-delay: 10.8s;
}
.lazy-load .lazy-item:nth-child(73n) {
  transition-delay: 10.95s;
}
.lazy-load .lazy-item:nth-child(74n) {
  transition-delay: 11.1s;
}
.lazy-load .lazy-item:nth-child(75n) {
  transition-delay: 11.25s;
}
.lazy-load .lazy-item:nth-child(76n) {
  transition-delay: 11.4s;
}
.lazy-load .lazy-item:nth-child(77n) {
  transition-delay: 11.55s;
}
.lazy-load .lazy-item:nth-child(78n) {
  transition-delay: 11.7s;
}
.lazy-load .lazy-item:nth-child(79n) {
  transition-delay: 11.85s;
}
.lazy-load .lazy-item:nth-child(80n) {
  transition-delay: 12s;
}
.lazy-load .lazy-item:nth-child(81n) {
  transition-delay: 12.15s;
}
.lazy-load .lazy-item:nth-child(82n) {
  transition-delay: 12.3s;
}
.lazy-load .lazy-item:nth-child(83n) {
  transition-delay: 12.45s;
}
.lazy-load .lazy-item:nth-child(84n) {
  transition-delay: 12.6s;
}
.lazy-load .lazy-item:nth-child(85n) {
  transition-delay: 12.75s;
}
.lazy-load .lazy-item:nth-child(86n) {
  transition-delay: 12.9s;
}
.lazy-load .lazy-item:nth-child(87n) {
  transition-delay: 13.05s;
}
.lazy-load .lazy-item:nth-child(88n) {
  transition-delay: 13.2s;
}
.lazy-load .lazy-item:nth-child(89n) {
  transition-delay: 13.35s;
}
.lazy-load .lazy-item:nth-child(90n) {
  transition-delay: 13.5s;
}
.lazy-load .lazy-item:nth-child(91n) {
  transition-delay: 13.65s;
}
.lazy-load .lazy-item:nth-child(92n) {
  transition-delay: 13.8s;
}
.lazy-load .lazy-item:nth-child(93n) {
  transition-delay: 13.95s;
}
.lazy-load .lazy-item:nth-child(94n) {
  transition-delay: 14.1s;
}
.lazy-load .lazy-item:nth-child(95n) {
  transition-delay: 14.25s;
}
.lazy-load .lazy-item:nth-child(96n) {
  transition-delay: 14.4s;
}
.lazy-load .lazy-item:nth-child(97n) {
  transition-delay: 14.55s;
}
.lazy-load .lazy-item:nth-child(98n) {
  transition-delay: 14.7s;
}
.lazy-load .lazy-item:nth-child(99n) {
  transition-delay: 14.85s;
}
.lazy-load .lazy-item:nth-child(100n) {
  transition-delay: 15s;
}
.lazy-load.loaded * {
  opacity: 1;
}
.lazy-load.loaded .lazy-item * {
  opacity: 1;
}

.map-holder {
  width: 100%;
}
.map-holder iframe {
  width: 100%;
  display: block;
  pointer-events: none;
}
.map-holder iframe.clicked {
  pointer-events: auto;
}

.news-grid {
  padding: 4rem 0;
}
.news-grid h4 {
  font-size: 1.3rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 0.4rem;
}
.news-grid h5 {
  font-size: 0.9rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.news-grid .results {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (width <= 990px) {
  .news-grid .results {
    flex-direction: column-reverse;
    gap: 1rem;
  }
}
.news-grid .results .order_by {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.news-grid .results .order_by .material-symbols-outlined {
  transform: rotate(0);
  transform-origin: center;
  transition: transform 0.15s ease-out;
}
.news-grid .results .order_by.reverse .material-symbols-outlined {
  transform: rotate(-180deg);
}
.news-grid .grid {
  padding-top: 1rem;
}
.news-grid .grid .news-item {
  position: relative;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.6s ease-in-out forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.news-grid .pagination-end {
  margin-top: 2rem;
}

.news-feed .title {
  color: #55565b;
  text-transform: uppercase;
  margin: 0 0 0.625rem;
  font-weight: 300;
  letter-spacing: 0.2143rem;
  font-size: 2.5rem;
}
.news-feed h4 {
  font-size: 1.3rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 0.4rem;
}
.news-feed h5 {
  font-size: 0.9rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.pagination-block .pagination {
  margin: 0;
}
.pagination-block .pagination .page-item {
  cursor: pointer;
}
.pagination-block .pagination .page-item .page-link {
  color: rgba(228, 44, 42, 0.6);
  padding: 0.625rem 0.9375rem;
  border-color: #ebedef;
  transition: background-color 0.2s ease-in-out;
}
.pagination-block .pagination .page-item .page-link:focus, .pagination-block .pagination .page-item .page-link:hover {
  background-color: rgba(228, 44, 42, 0.6);
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
}
.pagination-block .pagination .page-item.active .page-link {
  background-color: rgba(228, 44, 42, 0.6);
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
}
.pagination-block .pagination .page-item.active .page-link:focus, .pagination-block .pagination .page-item.active .page-link:hover {
  background-color: rgba(228, 44, 42, 0.6);
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
}
.pagination-block .pagination .page-item.disabled .page-link {
  background-color: #ebedef;
  border-color: #ebedef;
  color: #55565b;
}

.news-item {
  color: #575757;
  position: relative;
}
.news-item .post-img {
  margin-bottom: -2rem;
}
.news-item .post-img img {
  filter: grayscale(0);
  transition: filter linear 0.3s;
}
.news-item .post-text-block {
  position: relative;
  padding: 1.25rem;
  box-shadow: 0 0.1429rem 0.6429rem 0.07143rem rgba(0, 0, 0, 0.1);
  transition: box-shadow linear 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 18rem;
}
.news-item .post-text-block h5 {
  margin: 0;
}
.news-item .post-text-block p {
  margin-top: 1rem;
  margin-bottom: 0;
  color: #575757 !important;
}
.news-item .post-meta {
  position: absolute;
  bottom: 12%;
  margin-top: 0;
  left: 0;
  background: #f8f8f8;
  width: calc(100% - 2rem);
  margin: 0 1rem;
}
.news-item .post-meta .post-link-holder {
  line-height: 0.875rem;
}
.news-item .post-meta .post-link-holder p {
  display: inline-block;
  position: relative;
  padding-right: 1.429rem;
  padding-left: 1.2rem;
  color: #575757 !important;
}
.news-item .post-meta .post-link-holder .fa {
  position: absolute;
  right: 0;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translate(-1rem, -50%);
  transition: all linear 0.2s;
}
.news-item:hover .post-img img {
  filter: grayscale(100%);
  transition: filter linear 0.3s;
}
.news-item:hover .post-link-holder .fa {
  opacity: 1;
  visibility: visible;
  transform: translate(0, -50%);
  transition: all linear 0.2s;
}
.news-item:hover .post-text-block p {
  color: #575757 !important;
}

.preloader {
  background-color: #fff;
  width: 100vw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  overflow: hidden;
}
.preloader img {
  width: 140px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  border-radius: 6px;
  border: 3px solid #7f7f7f;
}

.news-header {
  padding-top: 8.5rem;
  padding-bottom: 1.5rem;
}
@media (width <= 990px) {
  .news-header {
    padding-top: 5rem;
  }
}

.news-text {
  padding-bottom: 1.5rem;
}
.news-text blockquote {
  border: none;
  color: #55565b;
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 0;
  padding: 0.625rem 1.25rem 0.625rem 3.125rem;
  position: relative;
}
.news-text blockquote::before {
  position: absolute;
  font-family: roxine-font-icon !important;
  content: "\f2ff";
  top: 0;
  left: 0;
  font-size: 1.875rem;
}

.news-share-buttons {
  padding-top: 2rem;
  padding-bottom: 4rem;
}
@media (width <= 990px) {
  .news-share-buttons strong {
    display: none;
  }
  .news-share-buttons .socials {
    gap: 0.2rem 2rem;
    justify-content: center;
  }
}
.news-share-buttons .socials {
  list-style: none;
  display: flex;
  font-size: 19px;
  margin: 0;
  padding: 0;
  -moz-column-gap: 1.2rem;
       column-gap: 1.2rem;
  flex-wrap: wrap;
}
.news-share-buttons .socials .button {
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5a5a5a;
  padding: 0;
  transition: color 0.3s ease, opacity 0.3s ease;
  cursor: pointer;
}
.news-share-buttons .socials .button img {
  height: 24px;
  margin-right: 4px;
}
.news-share-buttons .socials .button:hover {
  opacity: 0.7;
  color: #e42c2a;
  transition: color 0.3s ease, opacity 0.3s ease;
}

.gallery {
  margin: 6rem 0;
}
@media (width <= 990px) {
  .gallery {
    margin: 3rem 0;
  }
}
.gallery swiper-container {
  width: 100%;
  height: 400px;
}
.gallery swiper-container swiper-slide {
  height: auto;
  display: flex;
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.gallery swiper-container swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.gallery .slider-nav {
  position: absolute;
  cursor: pointer;
  font-size: 4.5rem;
  transform: translateY(-50%);
}
.gallery .slider-nav.next {
  top: 50%;
  right: -4rem;
}
.gallery .slider-nav.prev {
  top: 50%;
  left: -4rem;
}
.gallery .slider-nav.swiper-button-disabled {
  cursor: not-allowed;
  opacity: 0.2;
}
.gallery .slider-nav.swiper-button-lock {
  display: none;
}
@media (width <= 990px) {
  .gallery .slider-nav {
    display: none;
  }
}

.sponsor .container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.sponsor .container img {
  transition: transform 0.5s;
}
.sponsor .container img:hover {
  transform: scale(1.05);
}
@media (width <= 1280px) {
  .sponsor .container {
    flex-wrap: wrap;
  }
  .sponsor .container img {
    width: 8rem;
    height: auto;
  }
}

.video-player {
  padding: 2rem 0;
}
.video-player .content {
  position: relative;
}
.video-player .content .video-grid {
  display: grid;
  background-color: #000;
}
.video-player .content .video-grid .cover {
  grid-area: 1/1/2/2;
  z-index: 1;
}
.video-player .content .video-grid .cover img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.video-player .content .video-grid .video {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  display: grid;
  grid-area: 1/1/2/2;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
}
.video-player .content .video-grid .video iframe {
  grid-area: 1/1/2/2;
  width: 100%;
  height: 100%;
  border: 0;
  z-index: 2;
}
.video-player .content .video-grid .video video {
  grid-area: 1/1/2/2;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.video-player .content .video-grid .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  z-index: 20;
  transform: translate(-22.5px, 10px);
}
.video-player .content.video-playing .video-grid .cover {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out;
}
.video-player .content.video-playing .video-grid .video {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out;
}
.video-player .content.video-playing .video-grid .play-btn {
  display: none;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightbox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  opacity: 0.85;
  display: none;
  transition: opacity 0.2s;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
}

.lightbox {
  position: fixed;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: 0;
}
.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
}
.lightbox a img {
  border: 0;
}

.lb-outer-container {
  position: relative;
  --zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: #222;
}
.lb-outer-container::after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/content/assets/images/lightbox/loading.gif") no-repeat;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.lb-nav .lb-prev,
.lb-nav .lb-next {
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
  cursor: pointer;
  display: block;
  height: 100%;
  outline: 0;
}
.lb-nav .lb-prev:hover,
.lb-nav .lb-next:hover {
  opacity: 1;
}
.lb-nav .lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/content/assets/images/lightbox/prev.png") left 50% no-repeat;
  opacity: 0;
  transition: opacity 0.6s;
}
.lb-nav .lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/content/assets/images/lightbox/next.png") right 50% no-repeat;
  opacity: 0;
  transition: opacity 0.6s;
}

.lb-data-container {
  margin: 0 auto;
  padding-top: 5px;
  --zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.lb-data-container::after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #d2d2d2;
}
.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}
.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}
.lb-data .lb-caption a {
  color: #2291ff;
}
.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #353535;
}
.lb-data .lb-close {
  display: block;
  float: right;
  width: 20px;
  height: 20px;
  background: url("/content/assets/images/lightbox/close.png") top right no-repeat;
  text-align: right;
  outline: 0;
  opacity: 0.7;
  transition: opacity 0.2s;
  background-position: center;
  background-size: contain;
}
.lb-data .lb-close:hover {
  cursor: pointer;
  opacity: 1;
}

.grecaptcha-badge {
  z-index: 1999;
  animation: fadeOut 0.3s forwards;
  pointer-events: none;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.grecaptcha-badge.visible {
  animation: fadeIn 0.3s forwards;
  pointer-events: auto;
}

/* Define a transition duration during page visits */
html.is-changing .transition-fade {
  transition: opacity 0.25s;
  opacity: 1;
}

/* Define the styles for the unloaded pages */
html.is-animating .transition-fade {
  opacity: 0;
}

html,
body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  background: #fff;
}

img,
video {
  vertical-align: middle;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}